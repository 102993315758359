import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { setUserSession } from '../Utils/Common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APP_KEY, API_URL } from '../config';

function Login(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const headers = {
    headers : {
      "app-key" : APP_KEY
    }
  }
  // handle button click of login form
  const handleLogin = () => {    
    setError(null);
    setLoading(true);
    axios.post(API_URL+'/login', { email: username.value, password: password.value }, headers).then( async data => {
      setLoading(false);
      if(data.data.success === true) {
        await setUserSession(data.data.data.token, data.data);
        toast.success(data.data.message)
        props.history.push('/dashboard');
      } else {
        toast.error(data.data.message)
      }
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  }

  return (
    <div>
      <ToastContainer />
      <div className="login-box">
        <div className="login-logo">
          <img src="./logo.png" alt="" width="200"/>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">
            {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
          </p>
          <form>
            <div className="form-group has-feedback">
              <input type="email" className="form-control" {...username} placeholder="Email" />
              <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
            </div>
            <div className="form-group has-feedback">
              <input type="password" className="form-control" {...password} placeholder="Password" />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            <div className="row">
              <div className="col-xs-8">
                  <Link to="/forget">I forgot my password</Link>
              </div>
              <div className="col-xs-4 pull-right">
                <button type="submit" className="btn btn-primary btn-block btn-flat" disabled={loading} onClick={handleLogin} >{loading ? 'Loading...' : 'Login'}</button>
              </div>
            </div>
          </form>          
        </div>
      </div>
    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;