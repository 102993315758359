import React, { useEffect, useState } from "react";
import { API_URL, APP_KEY } from "./../config";
import axios from "axios";
import { getToken } from "./../Utils/Common";
import AppHeader from "./Header/Header";
import AppSidebar from "./Sidebar/Sidebar";
import AppFooter from "./Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Swal from "sweetalert2";

const token = getToken();
const headers = { headers: { "app-key": APP_KEY, "x-access-token": token } };

// CHANGE THESE TO PRODUCTION URL

// fetch url: https://delivery.api.hungrytop.store/webhook-urls/get-urls

// post url: https://delivery.api.hungrytop.store/webhook-urls/add-urls

// put url: PUT https://delivery.api.hungrytop.store/webhook-urls/update-urls/62587b42e39635b33565869d

const Webhook = () => {
  const [formData, setFormData] = useState({
    orderStatus: "",
    riderStatus: "",
  });

  const [_id, setID] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_URL}/webhook-urls/get-urls`, headers)
      .then((res) => {
        if (res.data.success && res.data.body.length !== 0) {
          setFormData({
            orderStatus: res.data.body[0].order_status_webhook,
            riderStatus: res.data.body[0].Rider_location_webhook,
          });

          setID(res.data.body[0]._id);
        }
      });
  }, []);

  const handleChange = (e) => {
    setFormData((f) => ({
      ...f,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const data = {
      Rider_location_webhook: formData.riderStatus,
      order_status_webhook: formData.orderStatus,
    };

    try {
      if (_id) {
        const { data: res } = await axios.put(
          `${API_URL}/webhook-urls/update-urls/${_id}`,
          data,
          headers
        );

        if (res.success) toast.success(res.message);
        else toast.error(res.message);
      } else {
        const { data: res } = await axios.post(
          `${API_URL}/webhook-urls/add-urls`,
          data,
          headers
        );

        if (res.success) toast.success(res.message);
        else toast.error(res.message);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <ToastContainer />
      <AppHeader />
      <AppSidebar />
      <div className="content-wrapper">
        <section className="content-header">
          <h1> webhook Settings </h1>
          <ol className="breadcrumb">
            <li>
              <a href="/#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="active">Webhook Settings</li>
          </ol>
        </section>

        <section className="content" style={{ width: "90%", marginLeft: 0 }}>
          <div className="row">
            <div className="col-xs-12">
              <div className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                  <li className="active">
                    <a
                      href="#rider-location"
                      data-toggle="tab"
                      aria-expanded="false"
                    >
                      Place your URL here
                    </a>
                  </li>
                </ul>
                <div className="tab-content no-padding">
                  <div
                    className="chart tab-pane active"
                    id="rider-location"
                    style={{ position: "relative" }}
                  >
                    <div style={{ margin: "0px 20px" }}>
                      <div className="box-body">
                        <form>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div className="form-group col-md-10 col-sm-12">
                              <label>
                                Order Status URL <i className="text-red">*</i>
                              </label>
                              <input
                                type="url"
                                name="orderStatus"
                                value={formData.orderStatus}
                                onChange={(e) => handleChange(e)}
                                id="email"
                                className="form-control "
                                placeholder="order status url"
                              />
                            </div>
                            <div className="form-group col-md-10 col-sm-12">
                              <label>
                                Rider Location URL <i className="text-red">*</i>
                              </label>
                              <input
                                type="url"
                                name="riderStatus"
                                value={formData.riderStatus}
                                onChange={(e) => handleChange(e)}
                                id="sender_id"
                                className="form-control "
                                placeholder="rider location url"
                                // maxlength="50"
                              />
                              {/* <div className="text-red">
                                {this.state.errors["sender_id"]}
                              </div> */}
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary pull-right"
                            onClick={handleFormSubmit}
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Add Shift */}
      <div className="modal fade" id="modal-default">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">Add Shift</h4>
            </div>
            <div className="modal-body">
              <div className="box">
                <div className="box-body">
                  <div className="form-group">
                    <label>
                      Start Time <i className="text-red">*</i>
                    </label>
                    <input
                      type="time"
                      name="start_time"
                      id="start_time"
                      className="form-control clear"
                      placeholder="Start Time"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      End Time <i className="text-red">*</i>
                    </label>
                    <input
                      type="time"
                      name="end_time"
                      id="end_time"
                      className="form-control clear"
                      placeholder="End Time"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Clear
              </button>
              <button type="button" className="btn btn-primary">
                save
              </button>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default Webhook;
