import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import axios from "axios";
// import Home from './components/Home';
import { APP_KEY, API_URL } from "./config";
import PrivateRoute from "./Utils/PrivateRoute";
import PublicRoute from "./Utils/PublicRoute";
import { getToken, removeUserSession, setUserSession } from "./Utils/Common";

import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import VehicleType from "./components/VehicleType";
import DeliveryPartner from "./components/DeliveryPartner";
import SiteSetting from "./components/SiteSetting";
import CMSPages from "./components/CMSPages";
import Faq from "./components/Faq";
import Notifications from "./components/Notifications";
import Uservoucher from "./components/Uservoucher";
import Profile from "./components/Profile";
import Order from "./components/Order";
import Webhook from "./components/Webhooks";
import Restaurantreport from "./components/Restaurantreport";
import Dailydeliveryreport from "./components/Dailydeliveryreport";
import Deliveryboyreport from "./components/Deliveryboyreport";
import Logisticreport from "./components/Logisticreport";
import DeliveryBoy from "./components/DeliveryBoy";
import Forget from "./components/Forget";
import TrackOrders from "./components/TrackOrders/index"
import PropagateLoader from "react-spinners/PropagateLoader";
import TrackRider from "./components/TrackRider";
import TrackAllRiders from "./components/TrackAllRiders";

const headers = { headers: { "app-key": APP_KEY } };

function App() {
  const [authLoading, setAuthLoading] = useState(true);
  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    // alert(token);
    axios
      .get(API_URL + `/verifyToken?token=${token}`, headers)
      .then((response) => {
        if (response.data.success === true) {
          setUserSession(response.data.data.token, response.data);
          console.log(response.data);
        } else {
          removeUserSession();
        }
        setAuthLoading(false);
      })
      .catch((error) => {
        setAuthLoading(false);
      });
    setAuthLoading(false);
  }, []);

  if (authLoading && getToken()) {
    return (
      <div className="content text-center" style={{ marginTop: "200px" }}>
        <PropagateLoader color="#F39C12" loading={authLoading} size={25} />
      </div>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/forget" component={Forget} />
          <PublicRoute path="/track-order/:trackId/:orderId" component={TrackOrders} />
          <PublicRoute path="/track-rider/:riderId" component={TrackRider} />
          <PublicRoute path="/track-all-riders/:id" component={TrackAllRiders} />
          <div className="wrapper">
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/vehicletype" component={VehicleType} />
            <PrivateRoute path="/deliverypartner" component={DeliveryPartner} />
            <PrivateRoute path="/setting" component={SiteSetting} />
            <PrivateRoute path="/pages" component={CMSPages} />
            <PrivateRoute path="/webhook" component={Webhook} />
            <PrivateRoute path="/faq" component={Faq} />
            <PrivateRoute path="/notifications" component={Notifications} />
            <PrivateRoute path="/uservoucher" component={Uservoucher} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/orders" component={Order} />
            <PrivateRoute path="/deliveryboy" component={DeliveryBoy} />
            <PrivateRoute
              path="/restaurantreport"
              component={Restaurantreport}
            />
            <PrivateRoute
              path="/dailydeliveryreport"
              component={Dailydeliveryreport}
            />
            <PrivateRoute
              path="/deliveryboyreport"
              component={Deliveryboyreport}
            />
            <PrivateRoute path="/logisticreport" component={Logisticreport} />
          </div>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
