import React, {  useEffect, useState } from 'react';
import {API_URL} from '../../config'    ;
import { useParams } from "react-router-dom"
import axios from "axios";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
//   DirectionsRenderer,
} from '@react-google-maps/api'
import PropagateLoader from "react-spinners/PropagateLoader";

export default function TrackRider() {
    const { riderId } = useParams();

    // eslint-disable-next-line
    const [map, setMap] = useState(/** @type goolge.maps.Map */  (null));
    // const [directionsResponse, setDirectionsResponse] = useState(null)
    const [riderLat, setRiderLat] = useState(Number)
    const [riderLong, setRiderLong] = useState(Number)


    useEffect(  () => {
        async function fetchData() {
          const {data} = await axios.get(`${API_URL}/app/v1/track-rider/${riderId}`);
          console.log('====================================');
          console.log(data);
          console.log('====================================');
          const {
            riderDetails:{latitude},
            riderDetails:{longitude},
          } = data;
          console.log('====================================');
          console.log(" RIDER LAT-----",latitude,longitude);
          console.log('====================================');
        setRiderLat(parseFloat(latitude))
        setRiderLong(parseFloat(longitude))
        }
        fetchData()
      }, [riderId])


      const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      })
      
      const center = { lat: riderLat, lng: riderLong }



      if(!isLoaded){
        return <PropagateLoader color="#F39C12" loading={isLoaded} size={25} />
  }


  



  return (
   <>
             {/* <div>
            <div className="mapController">
              <div>
            Distance: {distance} | Duration: {duration} |
              </div>
             <div onClick={()=> map.panTo(center2)}><i className='fa fa-location-arrow'></i></div>
            </div>
        </div> */}
            {/* <div  className="map-container" /> */}



        <div style={{width:"100%", height:"100vh"}}>
{
  isLoaded && (        
  <GoogleMap
    center={center}
    zoom={15}
    mapContainerStyle={{ width: '100%', height: '100%' }}
    options={{
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
    }}
    onLoad={(map)=>setMap(map)}
  >
    <Marker position={center} label={"HT-RIDER"} />
  </GoogleMap>)
}
        </div>
   </>
  )
}
