import React, { Component } from 'react';
import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getToken } from './../Utils/Common';
import AppHeader from './Header/Header';
import AppSidebar from './Sidebar/Sidebar';
import AppFooter from './Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

const token = getToken();
const headers = { headers : { "app-key" : APP_KEY, "x-access-token" : token } }

class Faq extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.config = {}
        this.state = {
            loading : false,
            data : [],
            total : 0,
            page : 0,
            size : 10,
            search : "",
            columns : [{
                name: 'Question',
                selector: `question`,
            },{
                name: 'Action',
                cell: row => <><button className="btn btn-info" data-toggle="modal" data-target="#modal-default" onClick={this.getData.bind(this, row)}><i className="fa fa-pencil"></i></button>&nbsp;&nbsp;&nbsp;<button className="btn btn-danger" id={row._id} onClick={this.handleDelete.bind(this, row._id)}><i className="fa fa-trash-o"></i></button></>,
            }],
            is_update : false,
            edit_id : "",
            fields: {},
            errors: {},
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        if (!fields["question"]) {
          formIsValid = false;
          errors["question"] = "This field is required";
        }    
        // if (typeof fields["name"] !== "undefined") {
        //   if (!fields["name"].match(/^[a-zA-Z]+$/)) {
        //     formIsValid = false;
        //     errors["name"] = "Please enter only letters";
        //   }
        // }

        if (!fields["answer"]) {
            formIsValid = false;
            errors["answer"] = "This field is required";
        }
    
        this.setState({ errors: errors });
        return formIsValid;
      }

    handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
          
          swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
                let url = API_URL+`/faq/deletefaq?id=${id}`;
                axios.delete(url, headers).then(res => {
                    if(res.data.success === true) {
                        toast.success(res.data.message);
                        this.getDataList();
                    } else {
                        toast.error(res.data.message);
                    }
                })
                swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your record has been deleted.',
                    'success'
                )
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire(
                'Cancelled',
                'Your record is safe',
                'error'
              )
            }
          });
    }

    handleSubmit(e) {
        e.preventDefault();  
        this.setState({ loading : true });  
        if (this.handleValidation()) {
            var data = {
                question : this.state.question,
                answer : this.state.answer
            }

            axios.post(`${API_URL}/faq/faqsave`, data, headers ).then(res => {
                if(res.data.success === true) {
                    this.handleReset();
                    this.getDataList();
                    toast.success(res.data.message);
                    this.myRef.current.click();
                } else {
                    toast.error(res.data.message);
                    this.setState({ loading : false });
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading : false });
            })
        } else {
            //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    handleUpdate(e) {
        e.preventDefault();    
        this.setState({ loading : true });
        if (this.handleValidation()) {
            var data = {
                question : this.state.question,
                answer : this.state.answer
            }
            var id = this.state.edit_id;

            axios.put(`${API_URL}/faq/updatefaq?id=${id}`, data, headers ).then(res => {
                if(res.data.success === true) {                    
                    this.handleReset();
                    this.getDataList();
                    toast.success(res.data.message);
                    this.myRef.current.click();
                } else {
                    toast.error(res.data.message);
                    this.setState({ loading : false });
                }
            }).catch(err => {
                this.setState({ loading : false });
                console.log(err);
            })
        } else {
        //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    getData = async (data) => {
        // console.log(data)        
        let fields = this.state.fields;
        fields = {
            question : this.state.question,
            answer : this.state.answer
        };

        await this.setState({
            is_update : true,
            edit_id : data._id,
            question : data.question,
            answer : data.answer,
            errors : {},
            fields
        });
    }
    
    handleReset = async (e) => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        await this.setState({
            is_update : false,
            edit_id : "",
            question : "",
            answer : "",
            fields : {},
            errors : {},
            loading : false
        });        
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name ] : e.target.value });
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields, loading : false });        
    }

    handleSearch = async (e) => {
        await this.setState({ search : e.target.value });
        this.getDataList();
    }

    handlePage = async (page) => {
        await this.setState({ page : (page - 1)});
        this.getDataList();
    }

    getDataList = () => {
        let url = API_URL+`/faq/getallfaq?page=${this.state.page}&size=${this.state.size}&search=${this.state.search}`;
        axios.get(url, headers).then(res => {
            if(res.data.success === true) {
                // alert(res.data.data.totalDocs)
                this.setState({
                    total: res.data.data.totalDocs,
                    data: res.data.data.docs
                })
            }
        })
    }

    inputNumber = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    componentDidMount(){
        this.getDataList();
    }

    render() {
        return (<div>
            <ToastContainer />
            <AppHeader />
            <AppSidebar />  
            <div className="content-wrapper">
                <section className="content-header">
                <h1> Help / FAQ’s </h1>
                <ol className="breadcrumb">
                    <li><a href="/#"><i className="fa fa-dashboard"></i> Home</a></li>
                    <li className="active">Help / FAQ’s</li>
                </ol>
                </section>
        
                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">Help / FAQ’s List</h3>
                                </div>
                                <div className="box-body">
                                    <DataTable
                                        title={<div id="example1_filter" className="dataTables_filter"><label><input type="text" className="form-control input-sm" placeholder="Search..." aria-controls="example1" onInput={this.handleSearch} /></label><button className="btn btn-success pull-right" data-toggle="modal" data-target="#modal-default" onClick={this.handleReset.bind(this)}>Add Help / FAQ’s</button></div>}
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={this.state.total}
                                        paginationPerPage={this.state.size}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={this.handlePage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Help / FAQ’s */}
                    <div className="modal fade" id="modal-default">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleReset.bind(this)} ref={this.myRef}>
                                    <span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">{(this.state.is_update) ? 'Edit' : 'Add'} Help / FAQ’s</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="box">
                                        <div className="box-body">
                                            <div className="form-group">
                                                <label>Question <i className="text-red">*</i></label>
                                                <input type="text" name="question" id="question" className="form-control clear" placeholder="Question" onChange={this.handleChange} value={(this.state.is_update) ? this.state.question : null}/>
                                                <div className="text-red">{this.state.errors["question"]}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Answer <i className="text-red">*</i></label>
                                                <textarea name="answer" rows="6" id="answer" className="form-control clear" placeholder="Answer...." onChange={this.handleChange} defaultValue={(this.state.is_update) ? this.state.answer : null}></textarea>
                                                <div className="text-red">{this.state.errors['answer']}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">                            
                                    <button type="button" className="btn btn-default" data-dismiss="modal" ref={this.myRef} onClick={this.handleReset.bind(this)} >Clear</button>
                                    {
                                        (this.state.is_update)
                                        ? <button type="button" className="btn btn-primary" onClick={this.handleUpdate.bind(this)} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button>
                                        : <button type="button" className="btn btn-primary" onClick={this.handleSubmit.bind(this)} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Save'}</button>
                                    }                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter />
          </div>);
    }
}

export default Faq;