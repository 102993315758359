import React, { Component } from 'react';
import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Select from "react-select";
import { getToken } from './../Utils/Common';
import AppHeader from './Header/Header';
import AppSidebar from './Sidebar/Sidebar';
import AppFooter from './Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
const token = getToken();
const headers = { headers : { "app-key" : APP_KEY, "x-access-token" : token } }

class Order extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.config = {}
        this.state = {
            loading : false,
            data : [],
            total : 0,
            page : 0,
            size : 10,
            search : "",
            columns : [
                //     {
                //     name: 'Restaurant Name',
                //     selector: row => row.pickup_details.city,
                // },
                {
                    name: 'Order No',
                    selector: row => row.order_details.client_order_id,
                },
            {
                name: 'Customer Name',
                selector: row => row.drop_details.customer_name,
            },
            {
                name: 'Address',
                selector: row => row.drop_details.delivery_address,
            },
            {
                name: 'Mobile',
                selector: row => row.drop_details.delivery_mobile,
            },
            {
                name: 'Order Time',
                cell: row => <Moment format="DD-MMM-YYYY">{row.order_time}</Moment>,
            },
            {
                name: 'Rider Name',
                cell: row => row.rider[0] ?  row.rider[0].username : "Rider has not been assigned yet",
            },
            {
                name: 'Rider Contact',
                cell: row => row.rider[0] ?  row.rider[0].mobile : "Rider has not been assigned yet",
            },
            {
                name: 'Manual Assign',
                cell: row => row.rider[0] ? <><button className="btn btn-primary"  onClick={()=>{
                    this.handleReset.bind(this);
                    this.getDeliveryBoy(row._id,row.accept_delivery_boy);
                    this.setState({ order_id : row._id }); 
                }}>Assign Rider</button></> : "",
                // cell: row => row.rider[0] ?  row.rider[0].mobile : "",
            },
            {
                name: 'Track Order',
                cell: row => row.rider[0] ? <><a href={row.track_url} target="_blank" rel="noreferrer"><button className="btn btn-danger"><i className="fa fa-share"></i></button></a></> : "Rider has not been assigned yet",
            },
            {
                name: 'Status',
                cell: row => <>{(row.order_status===1 && row.pickup_status && row.delivery_status) ? <small class="label label-success">Delivered</small> : (row.order_status===1 && row.pickup_status) ? <small class="label label-success">Pickup</small> : (row.order_status === 1) ? <small class="label label-warning">Accepted</small> : (row.order_status===3) ? <small class="label label-danger">Cancelled</small> : <small class="label label-info">Non-Accepted</small>}</>,
            },
            // {
            //     name: 'Action',
            //     cell: row => <><button className="btn btn-danger" id={row._id} onClick={this.handleDelete.bind(this, row._id)}><i className="fa fa-trash-o"></i></button></>,
            // }
        ],
        is_update : false,
        delivery_boy : [],
        user_id : 'all',
        device_token : '',
        order_id:"",
        fields : {},
        errors : {},
        }
        this.getDeliveryBoy = this.getDeliveryBoy.bind(this);

    }


    handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
          
          swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "Once Cancel, you will not be able to recover this Order!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
                let url = API_URL+`/orders/${id}`;
                axios.delete(url, headers).then(res => {
                    if(res.data.success === true) {
                        toast.success(res.data.message);
                        this.getDataList();
                    } else {
                        toast.error(res.data.message);
                    }
                })
                swalWithBootstrapButtons.fire(
                    'Cancelled!',
                    'Poof! order cancel successfully',
                    'success'
                )
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire(
                'Cancelled',
                'Your record is safe',
                'error'
              )
            }
          });
    }

    handleSearch = async (e) => {
        await this.setState({ search : e.target.value });
        this.getDataList();
    }

    handlePage = async (page) => {
        await this.setState({ page : (page - 1)});
        this.getDataList();
    }

    getDataList = () => {
        let url = API_URL+`/orders?page=${this.state.page}&size=${this.state.size}&search=${this.state.search}`;
        axios.get(url, headers).then(res => {
            if(res.data.success === true) {
                // alert(res.data.data.totalDocs)
                this.setState({
                    total: res.data.data.totalDocs,
                    data: res.data.data.docs
                })
            }
        })
    }

    componentDidMount(){
        this.getDataList();
    }


    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        if (!fields["user_id"]) {
          formIsValid = false;
          errors["user_id"] = "This field is required";
        }    

        // if (!fields["title"]) {
        //     formIsValid = false;
        //     errors["title"] = "This field is required";
        // }

        // if (!fields["message"]) {
        //     formIsValid = false;
        //     errors["message"] = "This field is required";
        // }
    
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        console.log('====================================');
        console.log(this.state.loading);
        console.log('====================================');
        e.preventDefault();  
        this.setState({ loading : true });  
        console.log('====================================');
        console.log(this.state.loading);
        console.log('====================================');
        if (this.handleValidation()) {
            var data = {
                orderId:this.state.order_id,
                deviceToken : this.state.device_token,
                riderId : this.state.user_id,
            }
            console.log('====================================');
            console.log(data,this.state.loading);
            console.log('====================================');
            axios.post(`${API_URL}/assign-rider`, data, headers ).then(res => {
                if(res.data.success === true) {
                    this.handleReset();
                    this.getDataList();
                    toast.success(res.data.message);
                    this.myRef.current.click();
                } else {
                    toast.error(res.data.message);
                    this.setState({ loading : false });
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading : false });
            })
        } else {
            //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    handleReset = async (e) => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        await this.setState({
            is_update : false,
            user_id : "",
            title : "",
            message : "",
            fields : {},
            errors : {},
            loading : false
        });        
    }


    getDeliveryBoy =  (orderId,riderId) => {
        // console.log('====================================');
        // console.log(orderId, riderId);
        // console.log('====================================');
        let url = API_URL+`/check-available-rider`;
        var data = {
            orderId : orderId,
            riderId : riderId,
        }
        axios.post(url, data,headers).then(async res => {
            if(res.data.success === true) {
                var option = [];
                // option.push({ label: "All", value: "all" });                
                await res.data.body.map((v) => {
                    return option.push({ value: v.user_id, label: v.name, device_token: v.device_token });
                });
                await this.setState({ delivery_boy: option });
                document.querySelector("#openModal").click()
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.data.message,
                  })
            }
        })

        console.log('====================================');
        console.log(this.state.delivery_boy.length !== 0);
        console.log('====================================');
    }

    handleInputChange = async (options) => {
        // console.log(options);
        await this.setState({ "user_id" : options.value, 'device_token':options.device_token });
        let fields = this.state.fields;
        fields['user_id'] =  options.value;
    };

    render() {
        return (<div>
            <ToastContainer />
            <AppHeader />
            <AppSidebar />  
            <div className="content-wrapper">
                <section className="content-header">
                <h1> Order's </h1>
                <ol className="breadcrumb">
                    <li><a href="/#"><i className="fa fa-dashboard"></i> Home</a></li>
                    <li className="active">Order's</li>
                </ol>
                </section>
        
                <section className="content">
                    <div className="row">
                        <div className="col-lg-12 col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">Order's List</h3>
                                </div>
                                <div className="box-body">
                                    {/* {console.log(this.state.columns)} */}
                                    {console.log(this.state.data)}
                                    <DataTable
                                        title={<div id="example1_filter" className="dataTables_filter"><label><input type="text" className="form-control input-sm" placeholder="Search..." aria-controls="example1" onInput={this.handleSearch} /></label></div>}
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={this.state.total}
                                        paginationPerPage={this.state.size}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={this.handlePage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                                        {/* Manual assign modal */}
                                        <button style={{visibility:"hidden"}} data-toggle="modal" data-target="#modal-default" id="openModal" ></button>
                                        <div className="modal fade" id="modal-default" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleReset.bind(this)} ref={this.myRef}>
                                    <span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">Assign Other Rider </h4>
                                </div>
                                <div className="modal-body">
                                    <div className="box">
                                        <div className="box-body">
                                            <div className="form-group">
                                            
                                                <label>Delivery Boy <i className="text-red">*</i></label>
                                                <Select options={this.state.delivery_boy}  onChange={this.handleInputChange} />
                                                <div className="text-red">{this.state.errors["user_id"]}</div>
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Title <i className="text-red">*</i></label><input type="text" name="title" id="title" className="form-control clear" placeholder="title" onChange={this.handleChange}/>
                                                <div className="text-red">{this.state.errors["title"]}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Message <i className="text-red">*</i></label>
                                                <textarea name="message" rows="6" id="message" className="form-control clear" placeholder="message...." spellcheck="false" onChange={this.handleChange}></textarea>
                                                <div className="text-red">{this.state.errors["message"]}</div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">                            
                                    <button type="button" className="btn btn-default" data-dismiss="modal" ref={this.myRef} onClick={this.handleReset.bind(this)} >Clear</button>
                                    <button type="button" className="btn btn-primary" onClick={this.handleSubmit.bind(this)} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Save'}</button>          
                                </div>
                            </div>
                        </div>
                    </div>






                </section>
            </div>
            <AppFooter />
          </div>);
    }
}

export default Order;