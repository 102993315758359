import React, { Component } from 'react';
import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getToken } from './../Utils/Common';
import AppHeader from './Header/Header';
import AppSidebar from './Sidebar/Sidebar';
import AppFooter from './Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const token = getToken();
const headers = { headers : { "app-key" : APP_KEY, "x-access-token" : token } }

class CMSPages extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.config = {}
        this.state = {
            loading : false,
            data : [],
            total : 0,
            page : 0,
            size : 10,
            search : "",
            columns : [{
                name: 'Title',
                selector: `title`,
            },{
                name: 'Status',
                cell: row => <>{(row.status) ? <small className="label label-success">Active</small> : <small className="label label-danger">Deactive</small>}</>,
            },{
                name: 'Action',
                cell: row => <><button className="btn btn-info" data-toggle="modal" data-target="#modal-default" onClick={this.getData.bind(this, row)}><i className="fa fa-pencil"></i></button></>,
            }],
            is_update : false,
            edit_id : "",
            fields: {},
            errors: {},
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        if (!fields["description"]) {
            formIsValid = false;
            errors["description"] = "This field is required";
        }
    
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleUpdate(e) {
        e.preventDefault();    
        this.setState({ loading : true });
        if (this.handleValidation()) {
            var data = {
                status : this.state.status,
                description : this.state.description,
            }
            var id = this.state.edit_id;

            axios.put(`${API_URL}/cms/updatecmspage?id=${id}`, data, headers ).then(res => {
                if(res.data.success === true) {                    
                    this.handleReset();
                    this.getDataList();
                    toast.success(res.data.message);
                    this.myRef.current.click();
                } else {
                    toast.error(res.data.message);
                    this.setState({ loading : false });
                }
            }).catch(err => {
                this.setState({ loading : false });
                console.log(err);
            })
        } else {
        //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    getData = async (data) => {
        // console.log(data)        
        let fields = this.state.fields;
        fields = {
            title : data.title,
            status : data.status,
            description : data.description,
        };

        await this.setState({
            is_update : true,
            edit_id : data._id,
            title : data.title,
            status : data.status,
            description : data.description,
            errors : {},
            fields
        });
    }
    
    handleReset = async (e) => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        await this.setState({
            is_update : false,
            edit_id : "",
            title : "",
            status : "",
            description : "",
            fields : {},
            errors : {},
            loading : false
        });        
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name ] : e.target.value });
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields, loading : false });        
    }

    handleSearch = async (e) => {
        await this.setState({ search : e.target.value });
        this.getDataList();
    }

    handlePage = async (page) => {
        await this.setState({ page : (page - 1)});
        this.getDataList();
    }

    getDataList = () => {
        let url = API_URL+`/cms/getallcmspages?page=${this.state.page}&size=${this.state.size}&search=${this.state.search}`;
        axios.get(url, headers).then(res => {
            if(res.data.success === true) {
                // alert(res.data.data.totalDocs)
                this.setState({
                    total: res.data.data.totalDocs,
                    data: res.data.data.docs
                })
            }
        })
    }

    componentDidMount(){
        this.getDataList();
    }

    render() {
        return (<div>
            <ToastContainer />
            <AppHeader />
            <AppSidebar />  
            <div className="content-wrapper">
                <section className="content-header">
                <h1> CMS Pages </h1>
                <ol className="breadcrumb">
                    <li><a href="/#"><i className="fa fa-dashboard"></i> Home</a></li>
                    <li className="active">CMS Pages</li>
                </ol>
                </section>
        
                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">CMS Pages List</h3>
                                </div>
                                <div className="box-body">
                                    <DataTable
                                        title={<div id="example1_filter" className="dataTables_filter"><label><input type="text" className="form-control input-sm" placeholder="Search..." aria-controls="example1" onInput={this.handleSearch} /></label></div>}
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={this.state.total}
                                        paginationPerPage={this.state.size}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={this.handlePage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Vehicle Type */}
                    <div className="modal fade" id="modal-default">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleReset.bind(this)} ref={this.myRef}><span aria-hidden="true">&times;</span></button><h4 className="modal-title">Edit CMS Pages</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="box">
                                        <div className="box-body">
                                            <div className="form-group"><label>Title : </label><span> {this.state.title}</span></div>
                                            <div className="form-group">
                                                <label>Status :</label>
                                                <select className="form-control" name="status" id="status" onChange={this.handleChange} value={this.state.status}>
                                                    <option value="true">Active</option>
                                                    <option value="false">Deactive</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>Description <i className="text-red">*</i></label>
                                                <textarea name="description" rows="6" id="description" className="form-control" spellcheck="false" onChange={this.handleChange} defaultValue={this.state.description}></textarea>
                                                <div className="text-red">{this.state.errors["description"]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">                            
                                    <button type="button" className="btn btn-default" data-dismiss="modal" ref={this.myRef} onClick={this.handleReset.bind(this)} >Clear</button>
                                    <button type="button" className="btn btn-primary" onClick={this.handleUpdate.bind(this)} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button>        
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter />
          </div>);
    }
}

export default CMSPages;