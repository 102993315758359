import React, { Component } from 'react';
import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getToken } from './../Utils/Common';
import AppHeader from './Header/Header';
import AppSidebar from './Sidebar/Sidebar';
import AppFooter from './Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
// import Swal from 'sweetalert2';

const token = getToken();
const headers = { headers : { "app-key" : APP_KEY, "x-access-token" : token } }

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.ref_input = React.createRef()
        this.config = {}
        this.state = {
            loading : false,
            data : [],
            total : 0,
            page : 0,
            size : 10,
            search : "",
            columns : [{
                name: 'Name',
                selector: 'name',
            },{
                name: 'Title',
                selector: `title`,
            },{
                name: 'Description',
                selector: 'message',
            }],
            is_update : false,
            delivery_boy : [],
            user_id : 'all',
            fields : {},
            errors : {},
        }

        this.getDataList = this.getDataList.bind(this);
        this.getDeliveryBoy = this.getDeliveryBoy.bind(this);
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        if (!fields["user_id"]) {
          formIsValid = false;
          errors["user_id"] = "This field is required";
        }    

        if (!fields["title"]) {
            formIsValid = false;
            errors["title"] = "This field is required";
        }

        if (!fields["message"]) {
            formIsValid = false;
            errors["message"] = "This field is required";
        }
    
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        e.preventDefault();  
        this.setState({ loading : true });  
        if (this.handleValidation()) {
            var data = {
                user_id : this.state.user_id,
                title : this.state.title,
                message : this.state.message,
            }

            axios.post(`${API_URL}/notification`, data, headers ).then(res => {
                if(res.data.success === true) {
                    this.handleReset();
                    this.getDataList();
                    toast.success(res.data.message);
                    this.myRef.current.click();
                } else {
                    toast.error(res.data.message);
                    this.setState({ loading : false });
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading : false });
            })
        } else {
            //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }
    
    handleReset = async (e) => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        await this.setState({
            is_update : false,
            user_id : "",
            title : "",
            message : "",
            fields : {},
            errors : {},
            loading : false
        });        
    }

    handleInputChange = async (options) => {
        console.log('====================================');
        console.log(options);
        console.log('====================================');
        // console.log(options);
        await this.setState({ "user_id" : options.value });
        let fields = this.state.fields;
        fields['user_id'] =  options.value;
    };

    handleChange = async (e) => {
        // console.warn(e.target.name);
        await this.setState({ [e.target.name ] : e.target.value });
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields, loading : false });        
    }

    handleSearch = async (e) => {
        await this.setState({ search : e.target.value });
        this.getDataList();
    }

    handlePage = async (page) => {
        await this.setState({ page : (page - 1)});
        this.getDataList();
    }

    getDataList = () => {
        let url = API_URL+`/notification?page=${this.state.page}&size=${this.state.size}&search=${this.state.search}`;
        axios.get(url, headers).then(res => {
            if(res.data.success === true) {
                // alert(res.data.data.totalDocs)
                this.setState({
                    total: res.data.data.totalDocs,
                    data: res.data.data.docs
                })
            }
        })
    }

    getDeliveryBoy =  () => {
        let url = API_URL+`/delivery-boy/getAllDeliveryBoy?size=100000`;
        axios.get(url, headers).then(async res => {
            if(res.data.success === true) {
                var option = [];
                option.push({ label: "All", value: "all" });                
                await res.data.data.docs.map((v) => {
                    return option.push({ value: v._id, label: v.first_name+' '+v.last_name });
                });
                await this.setState({ delivery_boy: option });
            }
        })
    }

    componentDidMount() {
        let fields = this.state.fields;
        fields['user_id'] = 'all';
        this.getDataList();
        this.getDeliveryBoy();
        // console.log(this.state.delivery_boy)
    }

    render() {
        return (<div>
            <ToastContainer />
            <AppHeader />
            <AppSidebar />  
            <div className="content-wrapper">
                <section className="content-header">
                    <h1> Notification's </h1>
                    <ol className="breadcrumb">
                        <li><a href="/#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Notification's</li>
                    </ol>
                </section>
        
                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">Notification's List</h3>
                                </div>
                                <div className="box-body">
                                    <DataTable
                                        title={<div id="example1_filter" className="dataTables_filter"><label><input type="text" className="form-control input-sm" placeholder="Search..." aria-controls="example1" onInput={this.handleSearch} /></label><button className="btn btn-success pull-right" data-toggle="modal" data-target="#modal-default" onClick={this.handleReset.bind(this)}>Send Notification's</button></div>}
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={this.state.total}
                                        paginationPerPage={this.state.size}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={this.handlePage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Notification's */}
                    <div className="modal fade" id="modal-default">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleReset.bind(this)} ref={this.myRef}>
                                    <span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">{(this.state.is_update) ? 'Edit' : 'Add'} Notification's</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="box">
                                        <div className="box-body">
                                            <div className="form-group">
                                            
                                                <label>Delivery Boy <i className="text-red">*</i></label>
                                                <Select options={this.state.delivery_boy}  onChange={this.handleInputChange} />
                                                <div className="text-red">{this.state.errors["user_id"]}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Title <i className="text-red">*</i></label><input type="text" name="title" id="title" className="form-control clear" placeholder="title" onChange={this.handleChange}/>
                                                <div className="text-red">{this.state.errors["title"]}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Message <i className="text-red">*</i></label>
                                                <textarea name="message" rows="6" id="message" className="form-control clear" placeholder="message...." spellcheck="false" onChange={this.handleChange}></textarea>
                                                <div className="text-red">{this.state.errors["message"]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">                            
                                    <button type="button" className="btn btn-default" data-dismiss="modal" ref={this.myRef} onClick={this.handleReset.bind(this)} >Clear</button>
                                    <button type="button" className="btn btn-primary" onClick={this.handleSubmit.bind(this)} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Save'}</button>          
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter />
          </div>);
    }
}


export default Notifications;