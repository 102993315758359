import React, { useEffect, useState } from 'react'
import {API_URL} from '../../config'    ;
import axios from "axios";
import { MapContainer, TileLayer, Popup,CircleMarker  } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  useParams,
} from "react-router-dom";






export default function TrackAllRiders() {
  const { id } = useParams();
  var helperText;
  var colorOption = {}
    const [riderAllDetails, setRiderAllDetails] = useState([]);

   
    useEffect(  () => {
        async function getData() {
            const {data} = await axios.get(`${API_URL}/app/v1/track-all-riders/${id}`);
            const {
              riderDetails
            } = data;
            // console.log('====================================');
            // console.log("RIDER DETAILS-----", riderDetails  );
            // console.log('====================================');
          setRiderAllDetails(riderDetails)
        }
        getData()
    }, [id])
  return (
    <>

    <MapContainer center={ [22.497187,88.3241283]}  zoom={10} scrollWheelZoom={false}>
 <TileLayer
   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
 />

 {riderAllDetails && riderAllDetails.map((currentElement, index)=>{
           if(currentElement.user_id.working_status){
            if(currentElement.status === "allocate"){
                colorOption = { color: 'yellow' }
                helperText = `${currentElement.user_id.first_name} ${currentElement.user_id.last_name} is busy on another order!`
            }
            else{
                colorOption = { color: 'green' }
                helperText = `${currentElement.user_id.first_name} ${currentElement.user_id.last_name} is a idle rider and waiting to accept order`
            }
          }
          else{
            colorOption = { color: 'red' }
            helperText = `${currentElement.user_id.first_name} ${currentElement.user_id.last_name} is offline`
          }

          console.log('====================================');
          console.log([parseFloat(currentElement.latitude), parseFloat(currentElement.longitude)]);
          console.log('====================================');
   return (
     <>
     <div key={index}  style={{width:"100%", height:"100vh"}}>
         <CircleMarker center={[parseFloat(currentElement.latitude), parseFloat(currentElement.longitude)]} pathOptions={colorOption} radius={20} >
           <Popup>{helperText}</Popup>
         </CircleMarker>
     </div>
     </>
   )
 })}
</MapContainer>
 </>

    )
}
