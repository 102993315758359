import React, { Component } from 'react';
import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getToken } from './../Utils/Common';
import AppHeader from './Header/Header';
import AppSidebar from './Sidebar/Sidebar';
import AppFooter from './Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import Select from "react-select";
import { format } from "date-fns";
// import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";

// const today = new Date();
const token = getToken();
// alert(token);
const PaymentMode = ["Cash", "Cheque", "Online"];
const headers = { headers : { "app-key" : APP_KEY, "x-access-token" : getToken() } }

class Uservoucher extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.config = {}
        this.state = {
            headers : { headers : { "app-key" : APP_KEY, "x-access-token" : token } },
            loading : false,
            data : [],
            total : 0,
            page : 0,
            size : 10,
            search : "",
            columns : [{
                name: 'Name',
                selector: `name`,
            },{
                name: 'Mobile',
                selector: 'mobile',
            },{
                name: 'Amount',
                selector: 'amount',
            },{
                name: 'Payment Mode',
                selector: 'payment_mode',
                cell: row => PaymentMode[row.payment_mode],
            },{
                name: 'Date',
                cell: row => <Moment format="DD-MM-YYYY">{row.payment_date}</Moment>,
            },{
                name: 'Action',
                cell: row => <><button className="btn btn-info" data-toggle="modal" data-target="#modal-default" onClick={this.getData.bind(this, row)}><i className="fa fa-pencil"></i></button>&nbsp;&nbsp;&nbsp;<button className="btn btn-danger" id={row._id} onClick={this.handleDelete.bind(this, row._id)}><i className="fa fa-trash-o"></i></button></>,
            }],
            is_update : false,
            edit_id : "",
            payment_mode_id : "0",
            payment_date : new Date(),
            payment_name : "Cash",
            payment_mode : [{"value" : 0, "label" : "Cash"},{"value" : 1 ,"label" : "Cheque"},{"value" : 2 ,"label" : "Online"}],
            user_id : "",
            deliveryboy_name : "",
            date : new Date().toISOString(),
            fields: {},
            errors: {},
        }

        this.getDataList = this.getDataList.bind();
        this.getDeliveryBoy = this.getDeliveryBoy.bind();
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        
        if (!fields["user_id"]) {
          formIsValid = false;
          errors["user_id"] = "This field is required";
        }   

        if (!fields["amount"]) {
            formIsValid = false;
            errors["amount"] = "This field is required";
        }
        // if (!fields["payment_date"]) {
        //     formIsValid = false;
        //     errors["payment_date"] = "This field is required";
        // }
    
        this.setState({ errors: errors });
        return formIsValid;
      }

    handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
          
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                let url = API_URL+`/payment?id=${id}`;
                axios.delete(url, headers).then(res => {
                    if(res.data.success === true) {
                        toast.success(res.data.message);
                        this.getDataList();
                    } else {
                        toast.error(res.data.message);
                    }
                })
                swalWithBootstrapButtons.fire('Deleted!', 'Your record has been deleted.', 'success' );
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire(
                'Cancelled',
                'Your record is safe',
                'error'
              )
            }
          });
    }

    handleSubmit(e) {
        e.preventDefault();  
        this.setState({ loading : true });  
        if (this.handleValidation()) {
            var data = {
                user_id : this.state.user_id,
                amount : this.state.amount,
                payment_mode : this.state.payment_mode_id,
                payment_date : new Date(this.state.payment_date),
            }

            axios.post(`${API_URL}/payment/payment`, data, headers ).then(res => {
                if(res.data.success === true) {
                    this.handleReset();
                    this.getDataList();
                    toast.success(res.data.message);
                    this.myRef.current.click();
                } else {
                    toast.error(res.data.message);
                    this.setState({ loading : false });
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading : false });
            })
        } else {
            //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    handleUpdate(e) {
        e.preventDefault();    
        this.setState({ loading : true });
        if (this.handleValidation()) {
            var data = {
                user_id : this.state.user_id,
                amount : this.state.amount,
                payment_mode : this.state.payment_mode_id,
                payment_date : new Date(this.state.payment_date),
            }
            var id = this.state.edit_id;

            axios.put(`${API_URL}/payment/${id}`, data, headers ).then(res => {
                if(res.data.success === true) {                    
                    this.handleReset();
                    this.getDataList();
                    toast.success(res.data.message);
                    this.myRef.current.click();
                } else {
                    toast.error(res.data.message);
                    this.setState({ loading : false });
                }
            }).catch(err => {
                this.setState({ loading : false });
                console.log(err);
            })
        } else {
        //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    getData = async (data) => {
        // console.log(data.user_id)        
        let fields = this.state.fields;
        var date = new Date(data.payment_date);

        fields = {
            user_id : data.user_id,
            amount : data.amount,
            payment_date : format(date, "yyyy-MM-dd"),
        };

        await this.setState({
            is_update : true,
            edit_id : data._id,
            name : data.name,
            user_id : data.user_id,
            amount : data.amount,
            payment_date : format(date, "yyyy-MM-dd"),
            errors : {},
            fields
        });
    }
    
    handleReset = async (e) => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        await this.setState({
            is_update : false,
            edit_id : "",
            user_id : "",
            name : "",
            amount : "",
            payment_date : "",
            fields : {},
            errors : {},
            loading : false
        });        
    }

    handleInputChange = async (options) => {
        // console.log(options);
        await this.setState({ user_id : options.value, deliveryboy_name : options.label });
        let fields = this.state.fields;
        fields['user_id'] =  options.value;
    };

    handleInputChangePaymentMode = async (options) => {
        // console.log(options);
        await this.setState({ payment_mode_id : options.value, payment_name : options.label });
        let fields = this.state.fields;
        fields['payment_mode_id'] =  options.value;
    };

    handleChange = async (e) => {
        // console.log(e.target.name);
        // console.log(e.target.value);
        await this.setState({ [e.target.name ] : e.target.value });
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields, loading : false });        
    }

    handleSearch = async (e) => {
        await this.setState({ search : e.target.value });
        this.getDataList();
    }

    handlePage = async (page) => {
        await this.setState({ page : (page - 1)});
        this.getDataList();
    }

    getDataList = () => {
        let url = API_URL+`/payment/payment?page=${this.state.page}&size=${this.state.size}&search=${this.state.search}`;
        axios.get(url, headers).then(res => {
            if(res.data.success === true) {
                // alert(res.data.data.totalDocs)
                this.setState({
                    total: res.data.data.totalDocs,
                    data: res.data.data.docs
                })
            }
        })
    }

    getDeliveryBoy =  () => {
        let url = API_URL+`/delivery-boy/getAllDeliveryBoy?size=100000`;
        axios.get(url, this.state.headers).then(async res => {
            if(res.data.success === true) {
                var option = [];
                // option.push({ label: "All", value: "all" });                
                await res.data.data.docs.map((v) => {
                    return option.push({ value: v._id, label: v.first_name+' '+v.last_name });
                });
                await this.setState({ delivery_boy: option });
            }
        })
    }

    inputNumber = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    componentDidMount(){
        this.getDataList();
        this.getDeliveryBoy();
        this.setState({ user_id : "", deliveryboy_name : "-- Delivery Boy --"});
    }

    render() {
        return (<div>
            <ToastContainer />
            <AppHeader />
            <AppSidebar />  
            <div className="content-wrapper">
                <section className="content-header">
                <h1> User Wages </h1>
                    <ol className="breadcrumb">
                        <li><a href="/#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">User Wages</li>
                    </ol>
                </section>
        
                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">User Wages List</h3>
                                </div>
                                <div className="box-body">
                                    <DataTable
                                        title={<div id="example1_filter" className="dataTables_filter"><label><input type="text" className="form-control input-sm" placeholder="Search..." aria-controls="example1" onInput={this.handleSearch} /></label><button className="btn btn-success pull-right" data-toggle="modal" data-target="#modal-default" onClick={this.handleReset.bind(this)}>Add User Wages</button></div>}
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={this.state.total}
                                        paginationPerPage={this.state.size}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={this.handlePage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add User Wages */}
                    <div className="modal fade" id="modal-default">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleReset.bind(this)} ref={this.myRef}>
                                    <span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">{(this.state.is_update) ? 'Edit' : 'Add'} User Wages</h4>
                                </div>
                                <div className="modal-body">
                                    <div className="box">
                                        <div className="box-body">
                                            <div className="form-group">
                                                <label>Delivery Boy <i className="text-red">*</i></label>
                                                <Select value={{ value: this.state.user_id, label: this.state.deliveryboy_name }} options={this.state.delivery_boy} onChange={this.handleInputChange} />
                                                <div className="text-red">{this.state.errors["user_id"]}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Amount <i className="text-red">*</i></label>
                                                <input type="text" name="amount" id="amount" className="form-control clear" placeholder="Amount" maxlength="7" onChange={this.handleChange} onKeyPress={this.inputNumber} value={(this.state.is_update) ? this.state.amount : null}/>
                                                <div className="text-red">{this.state.errors["amount"]}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Payment Mode</label>
                                                <Select value={{ value: this.state.payment_mode_id, label: this.state.payment_name }} options={this.state.payment_mode} onChange={this.handleInputChangePaymentMode} />
                                                <div className="text-red">{this.state.errors["payment_mode"]}</div>
                                            </div>
                                            <div className="form-group">
                                                <label>Date</label>
                                                <input type="date" name="payment_date" id="payment_date" className="form-control" placeholder="Date..." onKeyDown={(e) => e.preventDefault()} maxlength="10" onChange={this.handleChange} value={(this.state.is_update) ? this.state.payment_date : null}/>
                                                <div className="text-red">{this.state.errors["payment_date"]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">                            
                                    <button type="button" className="btn btn-default" data-dismiss="modal" ref={this.myRef} onClick={this.handleReset.bind(this)} >Clear</button>
                                    {
                                        (this.state.is_update)
                                        ? <button type="button" className="btn btn-primary" onClick={this.handleUpdate.bind(this)} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button>
                                        : <button type="button" className="btn btn-primary" onClick={this.handleSubmit.bind(this)} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Save'}</button>
                                    }                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter />
          </div>);
    }
}

export default Uservoucher;