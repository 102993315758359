import React, { Component } from 'react'
import { APP_KEY, API_URL } from './../config'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { getToken } from './../Utils/Common'
import AppHeader from './Header/Header'
import AppSidebar from './Sidebar/Sidebar'
import AppFooter from './Footer/Footer'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
import Select from 'react-select'

const token = getToken()
const headers = { headers: { 'app-key': APP_KEY, 'x-access-token': token } }

class DeliveryBoy extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.myRefSubmit = React.createRef()
    this.config = {}
    this.state = {
      loading: false,
      data: [],
      vehicle_array: [],
      id_type_array: [],
      view_data: {},
      total: 0,
      page: 0,
      size: 10,
      search: '',
      columns: [
        {
          name: 'Name',
          cell: (row) => (
            <>
              {row.first_name} {row.last_name}
            </>
          ),
        },
        {
          name: 'Image',
          cell: (row) => (
            <>
              <img src={row.profile_image} width='50' alt='' />
            </>
          ),
        },
        {
          name: 'Mobile',
          selector: `mobile`,
        },
        {
          name: 'Email',
          selector: `email`,
        },
        {
          name: 'KYC',
          cell: (row) => (
            <>
              {row.is_approved ? (
                <small className='label label-success'>Approved</small>
              ) : (
                <small className='label label-warning'>Pending</small>
              )}
            </>
          ),
        },
        {
          name: 'Online',
          cell: (row) => (
            <>
              {row.working_status ? (
                <small className='label label-success'>Online</small>
              ) : (
                <small className='label label-danger'>Offline</small>
              )}
            </>
          ),
        },
        {
          name: 'Track Rider',
          cell: (row) => (
            <>
              {row.working_status ? (
                <a href={`https://delivery.hungrytop.in/track-rider/${row._id}`} target="_blank" rel="noreferrer"> 
                  <button className='btn btn-info'>Track Rider</button>
                </a>
              ) : (
                <button className='btn btn-danger'>Offline</button>
              )}
            </>
          ),
        },
        {
          name: 'Action',
          cell: (row) => (
            <>
              <button
                className='btn btn-info'
                data-toggle='modal'
                data-target='#modal-default'
                onClick={this.getData.bind(this, row)}
              >
                <i className='fa fa-pencil'></i>
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                className='btn btn-danger'
                id={row._id}
                onClick={this.handleDelete.bind(this, row._id)}
              >
                <i className='fa fa-trash-o'></i>
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                className='btn btn-success'
                id={row._id}
                data-toggle='modal'
                data-target='#modal-default-view'
                onClick={this.getData.bind(this, row)}
              >
                <i className='fa fa-eye'></i>
              </button>
            </>
          ),
        },
      ],
      is_update: false,
      edit_id: '',
      fields: {},
      errors: {},
    }
  }

  handleValidation() {
    let fields = this.state.fields
    let errors = {}
    let formIsValid = true

    if (!fields['first_name']) {
      formIsValid = false
      errors['first_name'] = 'This field is required'
    }

    if (!fields['last_name']) {
      formIsValid = false
      errors['last_name'] = 'This field is required'
    }

    if (!fields['mobile']) {
      formIsValid = false
      errors['mobile'] = 'This field is required'
    }

    if (!fields['email']) {
      formIsValid = false
      errors['email'] = 'This field is required'
    }

    if (!fields['username']) {
      formIsValid = false
      errors['username'] = 'This field is required'
    }

    if (!fields['password']) {
      formIsValid = false
      errors['password'] = 'This field is required'
    }

    if (!fields['vehicle_type']) {
      formIsValid = false
      errors['vehicle_type'] = 'This field is required'
    }

    if (!fields['profile_image']) {
      formIsValid = false
      errors['profile_image'] = 'This field is required'
    }

    if (!fields['id_type']) {
      formIsValid = false
      errors['id_type'] = 'This field is required'
    }

    if (!fields['id_number']) {
      formIsValid = false
      errors['id_number'] = 'This field is required'
    }

    if (!fields['front_image']) {
      formIsValid = false
      errors['front_image'] = 'This field is required'
    }

    this.setState({ errors: errors })
    return formIsValid
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ loading: true })
    if (this.handleValidation()) {
      var data = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        mobile: this.state.mobile,
        email: this.state.email,
        username: this.state.username,
        password: this.state.password,
        vehicle_type: this.state.vehicle_type,
        id_type: this.state.id_type,
        id_number: this.state.id_number,
        profile_image: this.state.profile_image,
        front_image: this.state.front_image,
      }

      axios
        .post(`${API_URL}/delivery-boy/createDeliveryBoy`, data, headers)
        .then((res) => {
          if (res.data.success === true) {
            this.handleReset()
            this.getDataList()
            toast.success(res.data.message)
            this.myRefSubmit.current.click()
          } else {
            toast.error(res.data.message)
            this.setState({ loading: false })
          }
        })
        .catch((err) => {
          console.log(err)
          this.setState({ loading: false })
        })
    } else {
      //   alert("Form has errors.");
      this.setState({ loading: false })
    }
  }

  handleUpdate(e) {
    e.preventDefault()
    this.setState({ loading: true })
    if (this.handleValidation()) {
      var data = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        mobile: this.state.mobile,
        email: this.state.email,
        vehicle_type: this.state.vehicle_type,
        id_type: this.state.id_type,
        id_number: this.state.id_number,
        profile_image: this.state.profile_image,
        front_image: this.state.front_image,
      }

      var id = this.state.edit_id
      axios
        .put(
          `${API_URL}/updateDeliveryBoy/updateDeliveryBoy/${id}`,
          data,
          headers
        )
        .then((res) => {
          if (res.data.success === true) {
            this.handleReset()
            this.getDataList()
            toast.success(res.data.message)
            this.myRefSubmit.current.click()
          } else {
            toast.error(res.data.message)
            this.setState({ loading: false })
          }
        })
        .catch((err) => {
          this.setState({ loading: false })
          console.log(err)
        })
    } else {
      //   alert("Form has errors.");
      this.setState({ loading: false })
    }
  }

  handleDelete = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this record!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          let url = API_URL + `/delivery-boy/deleteDeliveryBoy?id=${id}`
          axios.delete(url, headers).then((res) => {
            if (res.data.success === true) {
              toast.success(res.data.message)
              this.getDataList()
            } else {
              toast.error(res.data.message)
            }
          })
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your record has been deleted.',
            'success'
          )
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your record is safe',
            'error'
          )
        }
      })
  }

  handleApprove = (status) => {
    // e.preventDefault();
    var data = {
      delivery_boy_id: this.state.view_data._id,
      status: status,
    }

    axios
      .post(`${API_URL}/delivery-boy/deliveryBoyApproved`, data, headers)
      .then((res) => {
        if (res.data.success === true) {
          this.handleReset()
          this.getDataList()
          toast.success(res.data.message)
          this.myRef.current.click()
        } else {
          toast.error(res.data.message)
          this.setState({ loading: false })
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({ loading: false })
      })
  }

  handleActiveDeactive = (status) => {
    // e.preventDefault();
    var data = {
      delivery_boy_id: this.state.view_data._id,
      status: status,
    }

    axios
      .post(`${API_URL}/delivery-boy/deliveryBoyStatus`, data, headers)
      .then((res) => {
        if (res.data.success === true) {
          this.handleReset()
          this.getDataList()
          toast.success(res.data.message)
          this.myRef.current.click()
        } else {
          toast.error(res.data.message)
          this.setState({ loading: false })
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({ loading: false })
      })
  }

  getData = async (data) => {
    // console.log(data)
    let fields = this.state.fields

    fields['first_name'] = data.first_name
    fields['last_name'] = data.last_name
    fields['mobile'] = data.mobile
    fields['email'] = data.email
    fields['username'] = data.username
    fields['password'] = data.password
    fields['vehicle_type'] = data.vehicle_type
    fields['id_type'] = data.id_type
    fields['id_number'] = data.id_number
    fields['profile_image'] = data.profile_image
    fields['front_image'] = data.front_image

    var vehicle_name = {}
    var v_type = data.vehicle_type
    vehicle_name = await this.state.vehicle_array.filter(
      (item) => item.value === v_type
    )

    var id_type_name = {}
    var id_type = data.id_type.toString()
    id_type_name = await this.state.id_type_array.filter(
      (item) => item.value === id_type
    )

    await this.setState({
      view_data: data,
      is_update: true,
      vehicle_name: vehicle_name[0],
      id_type_name: id_type_name[0],
      edit_id: data._id,
      errors: {},
      fields,
    })
  }

  handleReset = async (e) => {
    Array.from(document.querySelectorAll('input')).forEach(
      (input) => (input.value = '')
    )
    await this.setState({
      is_update: false,
      edit_id: '',
      fields: {},
      errors: {},
      loading: false,
    })
  }

  handleChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value })
    let fields = this.state.fields
    fields[e.target.name] = e.target.value
    this.setState({ fields, loading: false })
  }

  handleChangeVehicleType = async (options) => {
    this.setState({ vehicle_type: options.value })
    let fields = this.state.fields
    fields['vehicle_type'] = options.value
    this.setState({ fields, loading: false })
  }

  handleChangeIdType = async (options) => {
    this.setState({ id_type: options.value })
    let fields = this.state.fields
    fields['id_type'] = options.value
    this.setState({ fields, loading: false })
  }

  handleSearch = async (e) => {
    await this.setState({ search: e.target.value })
    this.getDataList()
  }

  handlePage = async (page) => {
    await this.setState({ page: page - 1 })
    this.getDataList()
  }

  getDataList = () => {
    let url =
      API_URL +
      `/delivery-boy/getAllDeliveryBoy?page=${this.state.page}&size=${this.state.size}&search=${this.state.search}`
    axios.get(url, headers).then((res) => {
      if (res.data.success === true) {
        // alert(res.data.data.totalDocs)
        this.setState({
          total: res.data.data.totalDocs,
          data: res.data.data.docs,
        })
      }
    })
  }

  getVehicleList = () => {
    let url = API_URL + `/app/v1/vehicletype`
    axios.get(url, headers).then(async (res) => {
      if (res.data.success === true) {
        // alert(res.data.data)
        var option = []
        // option.push({ label: "All", value: "all" });
        await res.data.data.map((v) => {
          return option.push({ value: v._id, label: v.name })
        })

        this.setState({ vehicle_array: option })
      }
    })
  }

  getIdTypeList = () => {
    let url = API_URL + `/app/v1/identity_types`
    axios.get(url, headers).then(async (res) => {
      if (res.data.success === true) {
        // alert(res.data.data)
        var option = []
        // option.push({ label: "All", value: "all" });
        await res.data.data.map((v) => {
          return option.push({ value: v._id, label: v.name })
        })

        this.setState({ id_type_array: option })
      }
    })
  }

  inputNumber = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }

  uploadImage = async (e) => {
    const data = new FormData()
    data.append('avatar', e.target.files[0])
    var headers = {
      headers: { 'app-key': APP_KEY, 'content-type': 'multipart/form-data' },
    }
    axios
      .post(`${API_URL}/upload`, data, headers)
      .then(async (res) => {
        if (res.data.success === true) {
          let fields = this.state.fields
          fields[e.target.name] = res.data.data.path
          await this.setState({ [e.target.name]: res.data.data.path })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  componentDidMount() {
    this.getDataList()
    this.getVehicleList()
    this.getIdTypeList()
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <AppHeader />
        <AppSidebar />
        <div className='content-wrapper'>
          <section className='content-header'>
            <h1> Delivery Boy </h1>
            <ol className='breadcrumb'>
              <li>
                <a href='/#'>
                  <i className='fa fa-dashboard'></i> Home
                </a>
              </li>
              <li className='active'>Delivery Boy</li>
            </ol>
          </section>

          <section className='content'>
            <div className='row'>
              <div className='col-xs-12'>
                <div className='box'>
                  <div className='box-header'>
                    <h3 className='box-title'>Delivery Boy List</h3>
                  </div>
                  <div className='box-body'>
                    <DataTable
                      title={
                        <div id='example1_filter' className='dataTables_filter'>
                          <label>
                            <textArea
                              type='text'
                              autoFocus='false'
                              autoComplete='false'
                              className='form-control input-sm'
                              placeholder='Search...'
                              onInput={this.handleSearch}
                            />
                          </label>
                          <a href="track-all-riders/1234" target="_blank">
                            <button
                            style={{marginLeft:'10px'}}
                              className='btn btn-primary pull-right'
                            >
                              Track All Rider
                            </button>
                          </a>
                          <button
                            className='btn btn-success pull-right'
                            data-toggle='modal'
                            data-target='#modal-default'
                            onClick={this.handleReset.bind(this)}
                          >
                            Add Delivery Boy
                          </button>
                        </div>
                      }
                      columns={this.state.columns}
                      data={this.state.data}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={this.state.total}
                      paginationPerPage={this.state.size}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      onChangePage={this.handlePage}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Add Delivery Boy */}
            <div className='modal fade' id='modal-default'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <button
                      type='button'
                      className='close'
                      data-dismiss='modal'
                      aria-label='Close'
                      onClick={this.handleReset.bind(this)}
                      ref={this.myRefSubmit}
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                    <h4 className='modal-title'>
                      {this.state.is_update ? 'Edit' : 'Add'} Delivery Boy
                    </h4>
                  </div>
                  <div className='modal-body'>
                    <div className='box'>
                      <div className='box-body'>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label>
                              First Name <i className='text-red'>*</i>
                            </label>
                            <input
                              type='text'
                              name='first_name'
                              id='first_name'
                              className='form-control'
                              onChange={this.handleChange}
                              placeholder='Enter First Name'
                              maxlength='20'
                              defaultValue={this.state.view_data.first_name}
                            />
                            <div className='text-red'>
                              {this.state.errors['first_name']}
                            </div>
                          </div>
                          <div className='form-group col-md-6'>
                            <label>
                              Last Name <i className='text-red'>*</i>
                            </label>
                            <input
                              type='text'
                              name='last_name'
                              id='last_name'
                              className='form-control'
                              onChange={this.handleChange}
                              placeholder='Enter Last Name'
                              maxlength='20'
                              defaultValue={this.state.view_data.last_name}
                            />
                            <div className='text-red'>
                              {this.state.errors['last_name']}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label>
                              Mobile <i className='text-red'>*</i>
                            </label>
                            <input
                              type='text'
                              name='mobile'
                              id='mobile'
                              className='form-control'
                              onChange={this.handleChange}
                              placeholder='Mobile'
                              maxlength='10'
                              defaultValue={this.state.view_data.mobile}
                            />
                            <div className='text-red'>
                              {this.state.errors['mobile']}
                            </div>
                          </div>
                          <div className='form-group col-md-6'>
                            <label>
                              Email <i className='text-red'>*</i>
                            </label>
                            <input
                              type='text'
                              name='email'
                              id='email'
                              className='form-control'
                              onChange={this.handleChange}
                              placeholder='Email'
                              maxlength='40'
                              defaultValue={this.state.view_data.email}
                            />
                            <div className='text-red'>
                              {this.state.errors['email']}
                            </div>
                          </div>
                        </div>
                        {!this.state.is_update ? (
                          <>
                            <div className='row'>
                              <div className='form-group col-md-6'>
                                <label>
                                  Username <i className='text-red'>*</i>
                                </label>
                                <input
                                  type='text'
                                  name='username'
                                  id='username'
                                  className='form-control'
                                  placeholder='Enter Username'
                                  maxlength='20'
                                  onChange={this.handleChange}
                                  defaultValue={this.state.view_data.username}
                                />
                                <div className='text-red'>
                                  {this.state.errors['username']}
                                </div>
                              </div>
                              <div className='form-group col-md-6'>
                                <label>
                                  Password <i className='text-red'>*</i>
                                </label>
                                <input
                                  type='password'
                                  name='password'
                                  id='password'
                                  className='form-control '
                                  placeholder='Enter Password'
                                  maxlength='15'
                                  onChange={this.handleChange}
                                  defaultValue={this.state.view_data.password}
                                />
                                <div className='text-red'>
                                  {this.state.errors['password']}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}

                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label>
                              Vehicle Type <i className='text-red'>*</i>
                            </label>
                            <Select
                              value={this.state.vehicle_name}
                              options={this.state.vehicle_array}
                              onChange={this.handleChangeVehicleType}
                            />
                            <div className='text-red'>
                              {this.state.errors['vehicle_type']}
                            </div>
                          </div>
                          <div className='form-group col-md-6'>
                            <label>
                              Profile Image <i className='text-red'>*</i>
                            </label>
                            <input
                              type='file'
                              name='profile_image'
                              id='profile_image'
                              accept='image/png, image/jpeg'
                              onChange={this.uploadImage}
                            />
                            <div className='text-red'>
                              {this.state.errors['profile_image']}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label>
                              ID Type <i className='text-red'>*</i>
                            </label>
                            <Select
                              value={this.state.id_type_name}
                              options={this.state.id_type_array}
                              onChange={this.handleChangeIdType}
                            />
                            <div className='text-red'>
                              {this.state.errors['id_type']}
                            </div>
                          </div>
                          <div className='form-group col-md-6'>
                            <label>
                              ID Number <i className='text-red'>*</i>
                            </label>
                            <input
                              type='text'
                              name='id_number'
                              id='id_number'
                              className='form-control'
                              placeholder='Enter Id Number'
                              maxlength='15'
                              onChange={this.handleChange}
                              defaultValue={this.state.view_data.id_number}
                            />
                            <div className='text-red'>
                              {this.state.errors['id_number']}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-6'>
                            <label>
                              Front Image <i className='text-red'>*</i>
                            </label>
                            <input
                              type='file'
                              name='front_image'
                              id='front_image'
                              onChange={this.uploadImage}
                            />
                            <div className='text-red'>
                              {this.state.errors['front_image']}
                            </div>
                          </div>
                          <div className='form-group col-md-6'>
                            <label>Back Image</label>
                            <input
                              type='file'
                              name='back_image'
                              id='back_image'
                              onChange={this.uploadImage}
                            />
                            <div className='text-red'>
                              {this.state.errors['back_image']}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-default'
                      data-dismiss='modal'
                      ref={this.myRefSubmit}
                      onClick={this.handleReset.bind(this)}
                    >
                      Clear
                    </button>
                    {this.state.is_update ? (
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={this.handleUpdate.bind(this)}
                        disabled={this.state.loading}
                      >
                        {this.state.loading ? 'Loading...' : 'Submit'}
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={this.handleSubmit.bind(this)}
                        disabled={this.state.loading}
                      >
                        {this.state.loading ? 'Loading...' : 'Save'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* View Deail Delivery Boy */}
            <div className='modal fade' id='modal-default-view'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <button
                      type='button'
                      className='close'
                      data-dismiss='modal'
                      aria-label='Close'
                      onClick={this.handleReset.bind(this)}
                      ref={this.myRef}
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                    <h4 className='modal-title'>View Delivery Boy</h4>
                  </div>
                  <div className='modal-body'>
                    <div className='box'>
                      <div className='box-body'>
                        <div className='col-md-12'>
                          <div className='row'>
                            <div className='form-group'>
                              <label>Unique Id : </label>{' '}
                              <span className='pull-right'>
                                {this.state.view_data._id}
                              </span>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='form-group'>
                              <label>Name : </label>{' '}
                              <span className='pull-right'>
                                {this.state.view_data.first_name}{' '}
                                {this.state.view_data.last_name}
                              </span>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='form-group'>
                              <label>Username : </label>
                              <span className='pull-right'>
                                {this.state.view_data.username}
                              </span>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='form-group'>
                              <label>Mobile :</label>
                              <span className='pull-right'>
                                {this.state.view_data.mobile}
                              </span>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='form-group'>
                              <label>Email :</label>
                              <span className='pull-right'>
                                {this.state.view_data.email}
                              </span>
                            </div>
                          </div>
                          {/* <div className="row">
         <div className="form-group"><label>Vehicle Type :</label><span className="pull-right">{this.state.data.vehicle_type}</span></div>
      </div> */}
                          {/* <div className="row">
         <div className="form-group"><label>Rate Per Hour :</label><span className="pull-right">0</span></div>
      </div> */}
                          <div className='row'>
                            <div className='form-group'>
                              <label>Profile Image :</label>
                              <span className='pull-right'>
                                {this.state.view_data.profile_image ? (
                                  <img
                                    src={this.state.view_data.profile_image}
                                    width='100'
                                    alt=''
                                  />
                                ) : null}
                              </span>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='form-group'>
                              <label>Is Approved :</label>
                              <span className='pull-right'>
                                {this.state.view_data.is_approved ? (
                                  <small className='label label-success'>
                                    Approved
                                  </small>
                                ) : (
                                  <small className='label label-warning'>
                                    Pending
                                  </small>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='form-group'>
                              <label>Working Status :</label>
                              <span className='pull-right'>
                                {this.state.view_data.working_status ? (
                                  <small className='label label-success'>
                                    Online
                                  </small>
                                ) : (
                                  <small className='label label-danger'>
                                    Offline
                                  </small>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='form-group'>
                              <label>Current Status :</label>
                              <span className='pull-right'>
                                {this.state.view_data.status ? (
                                  <small className='label label-success'>
                                    Active
                                  </small>
                                ) : (
                                  <small className='label label-danger'>
                                    Deactive
                                  </small>
                                )}
                              </span>
                            </div>
                          </div>
                          {this.state.view_data.is_approved ? (
                            <>
                              <div className='row'>
                                <div className='form-group'>
                                  <label>ID Type :</label>
                                  <span className='pull-right'>
                                    {this.state.view_data.id_type}
                                  </span>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='form-group'>
                                  <label>ID Number :</label>
                                  <span className='pull-right'>
                                    {this.state.view_data.id_number}
                                  </span>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='form-group'>
                                  <label>Front Image :</label>
                                  <span className='pull-right'>
                                    {this.state.view_data.front_image ? (
                                      <img
                                        src={this.state.view_data.front_image}
                                        width='100'
                                        alt=''
                                      />
                                    ) : null}
                                  </span>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='form-group'>
                                  <label>Back Image :</label>
                                  <span className='pull-right'>
                                    {this.state.view_data.back_image ? (
                                      <img
                                        src={this.state.view_data.back_image}
                                        width='100'
                                        alt=''
                                      />
                                    ) : null}
                                  </span>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='form-group'>
                                  {!this.state.view_data.status ? (
                                    <>
                                      <button
                                        className='btn btn-success pull-right'
                                        onClick={() =>
                                          this.handleActiveDeactive(1)
                                        }
                                      >
                                        Active
                                      </button>
                                    </>
                                  ) : (
                                    <button
                                      className='btn btn-danger pull-right'
                                      style={{ marginRight: '10px' }}
                                      onClick={() =>
                                        this.handleActiveDeactive(0)
                                      }
                                    >
                                      Deactive
                                    </button>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className='row'>
                            {!this.state.view_data.is_approved ? (
                              <div className='form-group'>
                                <button
                                  className='btn btn-success pull-right'
                                  onClick={() => this.handleApprove(1)}
                                >
                                  Approve
                                </button>
                                <button
                                  className='btn btn-danger pull-right'
                                  style={{ marginRight: '10px' }}
                                  onClick={() => this.handleApprove(2)}
                                >
                                  Reject
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <AppFooter />
      </div>
    )
  }
}

export default DeliveryBoy
