import React, { Component } from 'react';
import { APP_KEY, API_URL } from './../config';
import { getToken } from './../Utils/Common';
import AppHeader from './Header/Header';
import AppSidebar from './Sidebar/Sidebar';
import AppFooter from './Footer/Footer';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import axios from 'axios';
import { Line } from 'react-chartjs-2';

const token = getToken();
const headers = { headers : { "app-key" : APP_KEY, "x-access-token" : token } }
// alert(token);
class Dashboard extends Component { 
  constructor(props) {
    super(props);
    this.state = {
        start_date : moment(new Date()).subtract(6, 'months').format("YYYY-MM-DD"),
        end_date : moment(new Date()).format("YYYY-MM-DD"),
        total_orders : 0,
        // partner_account : 0,
        delivery_boy : 0,
        hasError: false,
        data : [],
        label : [],
        options : {
          responsive: true, // Instruct chart js to respond nicely.
          // maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
    };

    this.getData = this.getData.bind(this);
    this.handleApply = this.handleApply.bind(this);
  }

  getData = () => {
    axios.get(`${API_URL}/dashboard?from_date=${this.state.start_date}&to_date=${this.state.end_date}`, headers)
    .then(res => {
        
        if(res.data.success === true) {
            this.setState({
                total_orders : res.data.data.total_orders,
                partner_account : res.data.data.partner_account,
                delivery_boy : res.data.data.delivery_boy,
                data : res.data.data.orders,
                label : res.data.data.months,
            });
        }
    })
  }

  componentWillMount = () => {
    // const user = getUser();
    this.getData();
  }

  handleEvent(event, picker) {
    // console.log(picker.startDate);
  }

  handleApply(event, picker) {
    let start = moment(picker.startDate).format("YYYY-MM-DD");
    let end = moment(picker.endDate).format("YYYY-MM-DD");
    // alert(start);
    this.setState({ start_date : start, end_date : end });
    this.getData();
  }

  handleCallback(start, end ) {
    // console.log(start, end);
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const data = {
      labels: this.state.label,
      datasets: [{
          label: 'Total Orders', // Name the series
          data: this.state.data, // Specify the data values array
          fill: false,
          borderColor: '#2196f3', // Add custom color border (Line)
          backgroundColor: '#2196f3', // Add custom color background (Points and Fill)
          borderWidth: 1 // Specify bar border width
      }]}
    const { options } = this.state;
    return (
      <div>
      <AppHeader />
      <AppSidebar />  
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Dashboard
            <small>Control panel</small>
          </h1>
          <ol className="breadcrumb">
            <li><a href="/#"><i className="fa fa-dashboard"></i> Home</a></li>
            <li className="active">Dashboard</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-lg-4 col-xs-6">
              <div className="small-box bg-aqua">
                <div className="inner">
                  <h3>{this.state.total_orders}</h3>
                  <p>Today's Orders</p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-xs-6">
              <div className="small-box bg-green">
                <div className="inner">
                  <h3>{this.state.partner_account}</h3>
                  <p>Restaurant Account</p>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-xs-6">
              <div className="small-box bg-yellow">
                <div className="inner">
                  <h3>{this.state.delivery_boy}</h3>
                  <p>Delivery Boy Account</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <section className="col-lg-12 connectedSortable">
                <div className="row">
                    <div className="form-group col-md-3 pull-right">
                        <label>Date range:</label>
                        <DateRangePicker initialSettings={{ startDate: moment(new Date()).subtract(6, 'months').toDate(), locale: { format: 'YYYY-MM-DD' } }} onApply={this.handleApply} onEvent={this.handleEvent} onCallback={this.handleCallback}> 
                            <input type="text" className="form-control" />
                        </DateRangePicker>
                    </div>                    
                </div>
                <div className="row">
                  <div className="col-lg-12 col-xs-12">
                    <Line data={data} options={options} />
                  </div>
                </div>
            </section>
          </div>
        </section>
      </div>
      <AppFooter />
      </div>
    );
  }
}

// Header.propTypes = {
//   // bla: PropTypes.string,
// };

// Header.defaultProps = {
//   // bla: 'test',
// };

export default Dashboard;