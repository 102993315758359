import React, { Component } from 'react';
import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getToken } from './../Utils/Common';
import AppHeader from './Header/Header';
import AppSidebar from './Sidebar/Sidebar';
import AppFooter from './Footer/Footer';
import 'react-toastify/dist/ReactToastify.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';

const token = getToken();
const headers = { headers : { "app-key" : APP_KEY, "x-access-token" : token } }

class Restaurantreport extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.config = {}
        this.state = {
            loading : false,
            data : [],
            csvdata : [],
            total : 0,
            page : 0,
            size : 10,
            columns : [{
                name: 'Restaurant Name',
                selector: `name`,
            },{
                name: 'Total Order',
                selector: `total_order`,
            }],
            start_date : "",
            end_date : "",
        }

        this.getDataList = this.getDataList.bind(this);
    }

    handleEvent = (event, picker) => {
        // console.log(picker.startDate);
    }

    handleApply = async (event, picker) => {
        let start = moment(picker.startDate).format("YYYY-MM-DD");
        let end = moment(picker.endDate).format("YYYY-MM-DD");
        // alert(start);
        await this.setState({ start_date : start, end_date : end });
        this.getDataList();
    }

    handleCallback(start, end ) {
        console.log(start, end);
    }

    // handlePage = async (page) => {
    //     await this.setState({ page : (page - 1)});
    //     // this.getDataList();
    // }

    getDataList =  () => {
        let url = API_URL+`/restaurantReport?start_date=${this.state.start_date}&end_date=${this.state.end_date}`;
        axios.get(url, headers).then( async res => {
            if(res.data.success === true) {
                let csvdata = this.state.csvdata;
                res.data.data.map((e) => {
                    return csvdata.push({ "Restaurant Name" : e.name, "Total Order" : e.total_order });
                });
                await this.setState({ data: res.data.data, csvdata : csvdata });
            }
        })
    }

    inputNumber = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    componentDidMount(){
        // this.getDataList();
    }

    render() {
        return (<div>
            <AppHeader />
            <AppSidebar />  
            <div className="content-wrapper">
                <section className="content-header">
                <h1>Restaurant Report </h1>
                <ol className="breadcrumb">
                    <li><a href="/#"><i className="fa fa-dashboard"></i> Home</a></li>
                    <li className="active">Restaurant Report</li>
                </ol>
                </section>        
                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <div className="form-group col-md-3 pull-right">
                                        <label>Date range:</label>
                                        <DateRangePicker initialSettings={{ startDate: moment().subtract(1,'day').toDate(),    locale: { format: 'YYYY-MM-DD' } }} onApply={this.handleApply} onEvent={this.handleEvent} onCallback={this.handleCallback}> 
                                            <input type="text" className="form-control" />
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <div className="box-body">
                                    <DataTable
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        highlightOnHover
                                        pagination
                                    />                                    
                                </div>
                                {(this.state.data.length > 0) ? <><div class="box-footer">
                                    <div className="form-group col-md-2 pull-right">
                                        <CsvDownloader className="btn btn-info" filename="restaurant" extension=".csv" separator=";" datas={this.state.csvdata} text='Download CSV' />
                                    </div>
                                </div>
                                </> : null}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter />
          </div>);
    }
}

export default Restaurantreport;