import React, { PureComponent } from 'react';
import { getUser, removeUserSession } from '../../Utils/Common';
// import { useHistory } from 'react-router-dom';
// import PropTypes from 'prop-types';
//import { Test } from './Header.styles';
// const user = getUser();

class Header extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      name : '',
      hasError: false,
    };

    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout = () => {
    removeUserSession();
    window.location.href = '/';
    // this.props.history.push('/login');
    // this.props.navigation.navigate('/login');
  }
  // const user = getUser();
  // // handle click event of logout button
  // const handleLogout = () => {
  //   removeUserSession();
  //   props.history.push('/login');
  // }

  componentWillMount = () => {
    const user = getUser();
    // console.log(user)
    this.setState({ name : user.data.first_name+' '+user.data.last_name});
    // alert(user.data.first_name);
  }

  // componentDidMount = () => {
  //   console.log('Header mounted');
  // }

  // componentWillReceiveProps = (nextProps) => {
  //   console.log('Header will receive props', nextProps);
  // }

  // componentWillUpdate = (nextProps, nextState) => {
  //   console.log('Header will update', nextProps, nextState);
  // }

  // componentDidUpdate = () => {
  //   console.log('Header did update');
  // }

  // componentWillUnmount = () => {
  //   console.log('Header will unmount');
  // }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <div >
        <header className="main-header">
    <a href="/#" className="logo">
      <span className="logo-mini"><b>H</b>T</span>
      <span className="logo-lg"><b>Hungry</b>Top</span>
    </a>
    <nav className="navbar navbar-static-top">
      <a href="/#" className="sidebar-toggle" data-toggle="push-menu" role="button">
        <span className="sr-only">Toggle navigation</span>
      </a>
      <div className="navbar-custom-menu">
        <ul className="nav navbar-nav">
          <li className="dropdown user user-menu">
            <a href="/#" className="dropdown-toggle" data-toggle="dropdown">
              <img src={"/dist/img/user2-160x160.jpg"} className="user-image" alt="" />
              <span className="hidden-xs">{this.state.name}</span>
            </a>
          </li>
          <li>
            {/* <a href="/#" data-toggle="control-sidebar"><i className="fa fa-gears"></i></a> */}
            <a href="/#" onClick={this.handleLogout} ><i className="fa fa-sign-out"></i></a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
      </div>
    );
  }
}

// Header.propTypes = {
//   // bla: PropTypes.string,
// };

// Header.defaultProps = {
//   // bla: 'test',
// };

export default Header;
