import React, { Component } from 'react';
import { APP_KEY, API_URL } from './../config';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getToken } from './../Utils/Common';
import AppHeader from './Header/Header';
import AppSidebar from './Sidebar/Sidebar';
import AppFooter from './Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

const token = getToken();

const headers = { headers : { "app-key" : APP_KEY, "x-access-token" : token } }

class DeliveryPartner extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.config = {}
        this.state = {
            loading : false,
            data : [],
            total : 0,
            page : 0,
            size : 10,
            search : "",
            columns : [{
                name: 'Id',
                selector: `_id`,
            },{
                name: 'Name',
                selector: 'name',
            },{
                name: 'Address',
                selector: 'address',
            },{
                name: 'Mobile',
                selector: 'mobile',
            },{
                name: 'Email',
                selector: 'email',
            },{
                name: 'Contact Person',
                selector: 'contact_person',
            },{
                name: 'Action',
                cell: row => <><button className="btn btn-info" data-toggle="modal" data-target="#modal-default" onClick={this.getData.bind(this, row)}><i className="fa fa-pencil"></i></button>&nbsp;&nbsp;&nbsp;<button className="btn btn-danger" id={row._id} onClick={this.handleDelete.bind(this, row._id)}><i className="fa fa-trash-o"></i></button>&nbsp;&nbsp;&nbsp;<button className="btn btn-success" onClick={this.getData.bind(this, row)} data-toggle="modal" data-target="#view-modal-default"><i className="fa fa-eye"></i></button></>,
            }],
            is_update : false,
            edit_id : "",
            fields: {},
            errors: {},
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        if (!fields["name"]) {
          formIsValid = false;
          errors["name"] = "This field is required";
        }  
        if (!fields["address"]) {
            formIsValid = false;
            errors["address"] = "This field is required";
        } 
        if (!fields["mobile"]) {
            formIsValid = false;
            errors["mobile"] = "This field is required";
        } 
        if (typeof fields["mobile"] !== "undefined") {
            if (!fields["mobile"].match(/[0-9]/)) {
            formIsValid = false;
            errors["mobile"] = "Please enter only numbers";
            }
        }  
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "This field is required";
        }if (typeof fields["email"] !== "undefined") {
            if (!fields["email"].match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)) {
            formIsValid = false;
            errors["email"] = "Please enter valid email";
            }
        }

        if (!fields["contact_person"]) {
            formIsValid = false;
            errors["contact_person"] = "This field is required";
        }if (!fields["city"]) {
            formIsValid = false;
            errors["city"] = "This field is required";
        }if (!fields["state"]) {
            formIsValid = false;
            errors["state"] = "This field is required";
        }if (!fields["country"]) {
            formIsValid = false;
            errors["country"] = "This field is required";
        }if (!fields["pincode"]) {
            formIsValid = false;
            errors["pincode"] = "This field is required";
        }
        if (!fields["logo"]) {
            formIsValid = false;
            errors["logo"] = "This field is required";
        }

        if (!fields["latitude"]) {
            formIsValid = false;
            errors["latitude"] = "This field is required";
        }
        if (!fields["longitude"]) {
            formIsValid = false;
            errors["longitude"] = "This field is required";
        }
    
        this.setState({ errors: errors });
        return formIsValid;
      }

    handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
          })
          
          swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
                let url = API_URL+`/deleteDeliveryPartner?id=${id}`;
                axios.delete(url, headers).then(res => {
                    if(res.data.success === true) {
                        toast.success(res.data.message);
                        this.getDataList();
                    } else {
                        toast.error(res.data.message);
                    }
                })
                swalWithBootstrapButtons.fire('Deleted!','Your record has been deleted.','success');
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire(
                'Cancelled',
                'Your record is safe',
                'error'
              )
            }
          });
    }

    handleSubmit(e) {
        e.preventDefault();  
        this.setState({ loading : true });  
        if (this.handleValidation()) {
            var data = {
                name : this.state.name,
                address : this.state.address,
                mobile : this.state.mobile,
                email : this.state.email,
                contact_person : this.state.contact_person,
                city : this.state.city,
                state : this.state.state,
                country : this.state.country,
                pincode : this.state.pincode,
                logo : this.state.logo,
                latitude : this.state.latitude,
                longitude : this.state.longitude,
            }

            axios.post(`${API_URL}/deliveryPartnerSave`, data, headers ).then(res => {
                if(res.data.success === true) {
                    this.handleReset();
                    this.getDataList();
                    toast.success(res.data.message);
                    this.myRef.current.click();
                } else {
                    toast.error(res.data.message);
                    this.setState({ loading : false });
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading : false });
            })
        } else {
            //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    handleUpdate(e) {
        e.preventDefault();    
        this.setState({ loading : true });
        if (this.handleValidation()) {
            var data = {
                name : this.state.name,
                address : this.state.address,
                mobile : this.state.mobile,
                email : this.state.email,
                contact_person : this.state.contact_person,
                city : this.state.city,
                state : this.state.state,
                country : this.state.country,
                pincode : this.state.pincode,
                logo : this.state.logo,
                latitude : this.state.latitude,
                longitude : this.state.longitude,
            }

            var id = this.state.edit_id;
            axios.put(`${API_URL}/updateDeliveryPartner?id=${id}`, data, headers ).then(res => {
                if(res.data.success === true) {                    
                    this.handleReset();
                    this.getDataList();
                    toast.success(res.data.message);
                    this.myRef.current.click();
                } else {
                    toast.error(res.data.message);
                    this.setState({ loading : false });
                }
            }).catch(err => {
                this.setState({ loading : false });
                console.log(err);
            })
        } else {
        //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }

    getData = async (data) => {
        console.log(data)        
        let fields = this.state.fields;
        fields = {
            name : data.name,
            address : data.address,
            mobile : data.mobile,
            email : data.email,
            contact_person : data.contact_person,
            city : data.city,
            state : data.state,
            country : data.country,
            pincode : data.pincode,
            logo : data.logo,
            latitude : data.latitude,
            longitude : data.longitude,
        };
        
        await this.setState({
            is_update : true,
            edit_id : data._id,
            name : data.name,
            address : data.address,
            mobile : data.mobile,
            email : data.email,
            contact_person : data.contact_person,
            city : data.city,
            state : data.state,
            country : data.country,
            pincode : data.pincode,
            logo : data.logo,
            latitude : data.latitude,
            longitude : data.longitude,
            errors : {},
            fields
        });
        // console.log(this.state);
    }
    
    handleReset = async (e) => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        await this.setState({
            is_update : false,
            edit_id : "",
            name : "",
            address : "",
            mobile : "",
            email : "",
            contact_person : "",
            city : "",
            state : "",
            country : "",
            pincode : "",
            logo : "",
            latitude : "",
            longitude : "",
            fields : {},
            errors : {},
            loading : false
        });        
    }

    uploadImage = async (e) => {
        const data = new FormData();
        data.append('avatar', e.target.files[0]);
        var headers = { headers : { "app-key" : APP_KEY, 'content-type': 'multipart/form-data' } }
        axios.post(`${API_URL}/upload`, data, headers ).then( async res => {
            if(res.data.success === true) {
                let fields = this.state.fields;
                fields['logo'] = res.data.data.path;
                await this.setState({ [e.target.name] : res.data.data.path });
            }
        }).catch(err => {
            console.log(err);
        })
    }

    handleChange = async (e) => {
        await this.setState({ [e.target.name ] : e.target.value });
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields, loading : false });        
    }

    handleSearch = async (e) => {
        await this.setState({ search : e.target.value });
        this.getDataList();
    }

    handlePage = async (page) => {
        await this.setState({ page : (page - 1)});
        this.getDataList();
    }

    getDataList = () => {
        let url = API_URL+`/getAllDeliveryPartner?page=${this.state.page}&size=${this.state.size}&search=${this.state.search}`;
        axios.get(url, headers).then(res => {
            if(res.data.success === true) {
                // alert(res.data.data.totalDocs)
                this.setState({
                    total: res.data.data.totalDocs,
                    data: res.data.data.docs
                })
            }
        })
    }

    inputNumber = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    componentDidMount(){
        this.getDataList();
    }

    render() {
        return (<div>
            <ToastContainer />
            <AppHeader />
            <AppSidebar />  
            <div className="content-wrapper">
                <section className="content-header">
                <h1> Delivery Partner </h1>
                <ol className="breadcrumb">
                    <li><a href="/#"><i className="fa fa-dashboard"></i> Home</a></li>
                    <li className="active">Delivery Partner</li>
                </ol>
                </section>
        
                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="box-header">
                                    <h3 className="box-title">Delivery Partner List</h3>
                                </div>
                                <div className="box-body">
                                    <DataTable
                                        title={<div id="example1_filter" className="dataTables_filter"><label><input type="text" className="form-control input-sm" placeholder="Search..." aria-controls="example1" onInput={this.handleSearch} /></label><button className="btn btn-success pull-right" data-toggle="modal" data-target="#modal-default" onClick={this.handleReset.bind(this)}>Add Delivery Partner</button></div>}
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationTotalRows={this.state.total}
                                        paginationPerPage={this.state.size}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={this.handlePage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Delivery Partners */}
                    <div className="modal fade" id="modal-default">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleReset.bind(this)} ref={this.myRef}>
                                    <span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">{(this.state.is_update) ? 'Edit' : 'Add'} Vehicle Type</h4>
                                </div>
                                <div className="modal-body">
                                <div className="box">
                                    <div className="box-body">
                                        <div className="form-group">
                                            <label>Name <i className="text-red">*</i></label><input type="text" name="name" id="name" className="form-control " placeholder="Enter Delivery Partner" onChange={this.handleChange} value={(this.state.is_update) ? this.state.name : null} maxlength="30" />
                                            <div className="text-red">{this.state.errors["name"]}</div>
                                        </div>
                                        <div className="form-group">
                                            <label>Address <i className="text-red">*</i></label><input type="text" name="address" id="address" className="form-control " placeholder="Address...." onChange={this.handleChange} maxlength="50" value={(this.state.is_update) ? this.state.address : null} />
                                            <div className="text-red">{this.state.errors["address"]}</div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>Mobile <i className="text-red">*</i></label><input type="text" name="mobile" id="mobile" className="form-control " placeholder="Mobile" onChange={this.handleChange} onKeyPress={this.inputNumber} maxlength="10" value={(this.state.is_update) ? this.state.mobile : null} />
                                            <div className="text-red">{this.state.errors["mobile"]}</div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Email <i className="text-red">*</i></label><input type="text" name="email" id="email" className="form-control " placeholder="Email" onChange={this.handleChange} maxlength="40" value={(this.state.is_update) ? this.state.email : null} />
                                            <div className="text-red">{this.state.errors["email"]}</div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Contact Person <i className="text-red">*</i></label><input type="text" name="contact_person" id="contact_person" className="form-control " onChange={this.handleChange} placeholder="Contact Person" maxlength="30" value={(this.state.is_update) ? this.state.contact_person : null} />
                                            <div className="text-red">{this.state.errors["contact_person"]}</div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>City <i className="text-red">*</i></label><input type="text" name="city" id="city" className="form-control " placeholder="City" onChange={this.handleChange} maxlength="30" value={(this.state.is_update) ? this.state.city : null} />
                                            <div className="text-red">{this.state.errors["city"]}</div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>State <i className="text-red">*</i></label><input type="text" name="state" id="state" className="form-control " placeholder="State" onChange={this.handleChange} maxlength="30" value={(this.state.is_update) ? this.state.state : null} />
                                            <div className="text-red">{this.state.errors["state"]}</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>Country <i className="text-red">*</i></label><input type="text" name="country" id="country" className="form-control " placeholder="Country" onChange={this.handleChange} maxlength="30" value={(this.state.is_update) ? this.state.country : null} />
                                            <div className="text-red">{this.state.errors["country"]}</div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Pincode <i className="text-red">*</i></label><input type="text" name="pincode" id="pincode" className="form-control " placeholder="Pincode" onChange={this.handleChange} maxlength="6" value={(this.state.is_update) ? this.state.pincode : null} />
                                            <div className="text-red">{this.state.errors["pincode"]}</div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo <i className="text-red">*</i></label><input type="file" name="logo" id="logo" className="form-control " accept="image/*" onChange={this.uploadImage} />
                                            <div className="text-red">{this.state.errors["logo"]}</div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>Latitude <i className="text-red">*</i></label><input type="text" name="latitude" id="latitude" className="form-control " onChange={this.handleChange} placeholder="Latitude" maxlength="15" value={(this.state.is_update) ? this.state.latitude : null} />
                                            <div className="text-red">{this.state.errors["latitude"]}</div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Longitude <i className="text-red">*</i></label><input type="text" name="longitude" id="longitude" className="form-control" onChange={this.handleChange}  placeholder="Longitude" maxlength="15" value={(this.state.is_update) ? this.state.longitude : null} />
                                            <div className="text-red">{this.state.errors["longitude"]}</div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>                                    
                                </div>
                                <div className="modal-footer">                            
                                    <button type="button" className="btn btn-default" data-dismiss="modal" ref={this.myRef} onClick={this.handleReset.bind(this)} >Clear</button>
                                    {
                                        (this.state.is_update)
                                        ? <button type="button" className="btn btn-primary" onClick={this.handleUpdate.bind(this)} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button>
                                        : <button type="button" className="btn btn-primary" onClick={this.handleSubmit.bind(this)} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Save'}</button>
                                    }                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* View Delivery Partners */}
                    <div className="modal fade" id="view-modal-default">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                        <h4 className="modal-title">View Delivery Partner</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="box">
                                            <div className="box-body">
                                                <div className="form-group"><label>Unique Id : </label> <span className="pull-right">{this.state._id}</span></div>
                                                <div className="form-group"><label>Name : </label> <span className="pull-right">{this.state.name}</span></div>
                                                <div className="form-group"><label>Address : </label><span className="pull-right">{this.state.address}</span></div>
                                                <div className="form-group"><label>Mobile :</label><span className="pull-right">{this.state.mobile}</span></div>
                                                <div className="form-group"><label>Email :</label><span className="pull-right">{this.state.email}</span></div>
                                                <div className="form-group"><label>Contact Person :</label><span className="pull-right">{this.state.contact_person}</span></div>
                                                <div className="form-group"><label>City :</label><span className="pull-right">{this.state.city}</span></div>
                                                <div className="form-group"><label>State :</label><span className="pull-right">{this.state.state}</span></div>
                                                <div className="form-group"><label>Country :</label><span className="pull-right">{this.state.country}</span></div>
                                                <div className="form-group"><label>Pincode :</label><span className="pull-right">{this.state.pincode}</span></div>
                                                <div className="form-group"><label>Latitude :</label><span className="pull-right">{this.state.latitude}</span></div>
                                                <div className="form-group"><label>Longitude :</label><span className="pull-right">{this.state.longitude}</span></div>
                                                <div className="form-group"><label>Logo :</label><span className="pull-right"> {(this.state.logo ? <img width="250" alt="" src={this.state.logo} /> : 'No Image Found')} </span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
            </div>
            <AppFooter />
          </div>);
    }
}

export default DeliveryPartner;