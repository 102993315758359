import React from 'react';
import PropTypes from 'prop-types';
//import { Test } from './Footer.styles';

const Footer = (props) => (
  <div className="FooterWrapper">
    <footer className="main-footer">
      <div className="pull-right hidden-xs">
        <b>Version</b> 1.0
      </div>
      <strong>Copyright &copy; 2020-2021 <a href="/#">HungryTop</a></strong>
    </footer>
  </div>
);

Footer.propTypes = {
  bla: PropTypes.string,
};

Footer.defaultProps = {
  bla: 'test',
};

export default Footer;
