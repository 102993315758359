import React, {  useEffect, useState } from 'react';
import {API_URL} from '../../config'    ;
import { useParams } from "react-router-dom"
import axios from "axios";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from '@react-google-maps/api'
import PropagateLoader from "react-spinners/PropagateLoader";
// import riderPng from "../../assets/rider.png";
import restaurant from "../../assets/restaurant.png";
import home from "../../assets/home.png";

export default function TracOrders() {

  const { trackId,orderId } = useParams();

  const [map, setMap] = useState(/** @type goolge.maps.Map */  (null));
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const [allocate, setAllocate] = useState(Boolean)
  const [riderLat, setRiderLat] = useState(Number)
  const [riderLong, setRiderLong] = useState(Number)
  const [deliveryLat, setDeliveryLat] = useState(Number)
  const [deliveryLong, setDeliveryLong] = useState(Number)
  const [pickUpLatitude, setPickUpLatitude] = useState(Number)
  const [pickUpLongitude, setPickUpLongitude] = useState(Number)



  useEffect(  () => {
    async function fetchData() {
      const {data} = await axios.get(`${API_URL}/app/v1/track/${trackId}/${orderId}`);
      console.log('====================================');
      console.log(data);
      console.log('====================================');
      const {
        pickUpStatus,
        pickUpDetails:{longitude:pickUpLong,latitude:pickUpLat},
        dropDetails:{delivery_lat,delivery_long},
        riderDetails:{latitude,longitude},
      } = data;
      console.log('====================================');
      console.log("PICKUP STATUS",pickUpStatus);
      console.log('====================================');


      // console.log('====================================');
      // console.log("RIDER LAT-----",latitude,longitude);
      // console.log('====================================');
      // console.log('====================================');
      // console.log("DELIVERY LAT-----",delivery_lat,delivery_long);
      // console.log('====================================');
      setAllocate(pickUpStatus)
    setRiderLat(parseFloat(latitude))
    setRiderLong(parseFloat(longitude))
    setDeliveryLat(parseFloat(delivery_lat))
    setDeliveryLong(parseFloat(delivery_long))
    setPickUpLatitude(parseFloat(pickUpLat))
    setPickUpLongitude(parseFloat(pickUpLong))
    }
    fetchData()
  }, [trackId,orderId])



    const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  })
  const riderCenter = { lat: riderLat, lng: riderLong }
  const dropCenter = { lat: deliveryLat, lng: deliveryLong }
  const pickUPCenter = { lat: pickUpLatitude, lng: pickUpLongitude }


  async function calculateRoute() {
    if(!allocate){
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
          // eslint-disable-next-line no-undef
          origin:  new google.maps.LatLng(riderCenter),
          // eslint-disable-next-line no-undef
          destination:  new google.maps.LatLng(pickUPCenter),
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        })
        setDirectionsResponse(results)
        setDistance(results.routes[0].legs[0].distance.text)
        setDuration(results.routes[0].legs[0].duration.text)
    }
    else{
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
          // eslint-disable-next-line no-undef
          origin:  new google.maps.LatLng(riderCenter),
          // eslint-disable-next-line no-undef
          destination:  new google.maps.LatLng(dropCenter),
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        })
        setDirectionsResponse(results)
        setDistance(results.routes[0].legs[0].distance.text)
        setDuration(results.routes[0].legs[0].duration.text)
    }
  }


  // const iconObj = 
  


  if(!isLoaded){
        return <PropagateLoader color="#F39C12" loading={isLoaded} size={25} />
  }
  calculateRoute();
  return (
        <>

          <div>
            <div className="mapController">
              <div>
            Distance: {distance} | Duration: {duration} |
              </div>
             <div onClick={()=> map.panTo(dropCenter)}><i className='fa fa-location-arrow'></i></div>
            </div>
            {/* <div  className="map-container" /> */}
        </div>



        <div style={{width:"100%", height:"100vh"}}>
{
  isLoaded && (        
  <GoogleMap
    center={riderCenter}
    zoom={15}
    mapContainerStyle={{ width: '100%', height: '100%' }}
    options={{
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
    }}
    onLoad={(map)=>setMap(map)}
  >
    <Marker 
    position={ !allocate ? dropCenter : pickUPCenter }
    // label={allocate ? "Restaurent" :""} 
    icon={!allocate ?  {
      url: home,
      // eslint-disable-next-line no-undef
      scaledSize:  new google.maps.Size(35,45)
      } : {
        url: restaurant,
        // eslint-disable-next-line no-undef
        scaledSize:  new google.maps.Size(35,45)
        }}
    />
    {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} routeIndex={1}  />
          )}
  </GoogleMap>)
}
        </div>
        </>
  )
}