import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import { setUserSession } from '../Utils/Common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APP_KEY, API_URL } from '../config';

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [otpinput, setOtpInput] = useState(false);
  const [change, setChangePassword] = useState(false);
  const [otpvalue, setOtpValue] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  // const username = useFormInput('');
  // const otp = useFormInput('11111111');
  const [error, setError] = useState(null);
  const headers = {
    headers : {
      "app-key" : APP_KEY
    }
  }
  // handle button click of login form
  const handleForget = () => {
    setError(null);
    setLoading(true);
    axios.post(API_URL+'/forgetpassword', { email: email }, headers).then(data => {
      setLoading(false);
      if(data.data.success === true) {
        toast.success(data.data.message)
        setOtpInput(true);
        setOtpValue(data.data.data);
      } else {
        toast.error(data.data.message)
      }
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  }

  const handleForgetVerify = () => {
    setError(null);
    setLoading(true);
    axios.post(API_URL+'/forgetVerify', { email: email, otp : otpvalue }, headers).then(data => {
      setLoading(false);
      if(data.data.success === true) {        
        setOtpInput(false);
        setEmail(data.data.data);
        setChangePassword(true);
        toast.success(data.data.message);
      } else {
        toast.error(data.data.message);
      }
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  }

  const handleForgetChangePassword = () => {
    setError(null);
    setLoading(true);
    axios.post(API_URL+'/forgetchangepassword', { email: email, password : password, confirm_password : confirmpassword }, headers).then(data => {
      setLoading(false);
      if(data.data.success === true) {        
        toast.success(data.data.message);   
        setTimeout(() => {
          props.history.push('/login');
        }, 1000);
      } else {
        toast.error(data.data.message);
      }
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  }

  const inputNumber = (event) => {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
  }

  return (
    <div>
      <ToastContainer />
      <div className="login-box">
        <div className="login-logo">
          <img src="./logo.png" alt="" width="200"/>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">
            {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
          </p>
          <form>
            <div className="form-group has-feedback">
              <input type="email" className="form-control" name="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
              <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
            </div>
            {(otpinput)
            ? <div className="form-group has-feedback">
              <input type="text" className="form-control" onChange={(e) => setOtpValue(e.target.value)} maxlength="6" placeholder="OTP Code"  pattern="[0-9]*" onKeyPress={inputNumber} defaultValue={otpvalue} />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            : null}
            {(change) ? <><div class="form-group has-feedback">
              <input type="password" class="form-control" placeholder="Password" maxlength="8" onChange={(e) => setPassword(e.target.value)} />
              <span class="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            <div class="form-group has-feedback">
              <input type="password" class="form-control" placeholder="Confirm Password" maxlength="8" onChange={(e) => setConfirmPassword(e.target.value)} />
              <span class="glyphicon glyphicon-lock form-control-feedback"></span>
            </div></> : null}
            <div className="row">
              <div className="col-xs-4 pull-left">
                <Link to="/login" className="btn btn-primary btn-block btn-flat">Back</Link>
              </div>
              {(!otpinput && !change) ? 
              <div className="col-xs-4 pull-right">
                <button type="button" className="btn btn-primary btn-block btn-flat" onClick={handleForget} disabled={loading}>{loading ? 'Loading...' : 'Send'}</button>
              </div>
              : null}

              {(otpinput) ? 
                <div className="col-xs-4 pull-right">
                <button type="button" className="btn btn-primary btn-block btn-flat" onClick={handleForgetVerify} disabled={loading}>{loading ? 'Loading...' : 'Verify'}</button>
              </div> : null}

              {(change) ? 
                <div className="col-xs-4 pull-right">
                <button type="button" className="btn btn-primary btn-block btn-flat" onClick={handleForgetChangePassword} disabled={loading}>{loading ? 'Loading...' : 'Submit'}</button>
              </div> : null}
              
            </div>
          </form>          
        </div>
      </div>
    </div>
  );
}

// const useFormInput = initialValue => {
//   const [value, setValue] = useState(initialValue);

//   const handleChange = e => {
//     setValue(e.target.value);
//   }
//   return {
//     value,
//     onChange: handleChange
//   }
// }

export default Login;