import React, { Component } from 'react';
import { APP_KEY, API_URL } from './../config';
import axios from 'axios';
import { getToken } from './../Utils/Common';
import AppHeader from './Header/Header';
import AppSidebar from './Sidebar/Sidebar';
import AppFooter from './Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const token = getToken();
const headers = { headers : { "app-key" : APP_KEY, "x-access-token" : token } }

class Faq extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.config = {}
        this.state = {
            loading : false,
            loading1 : false,
            first_name : "",
            last_name : "",
            mobile : "",
            old_password : "",
            new_password : "",
            confirm_password : "",
            fields: {},
            errors: {},
            fields1: {},
            errors1: {},
        }

        this.handleProfile = this.handleProfile.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        if (!fields["first_name"]) {
            formIsValid = false;
            errors["first_name"] = "This field is required";
        } 

        if (!fields["last_name"]) {
            formIsValid = false;
            errors["last_name"] = "This field is required";
        }

        if (!fields["mobile"]) {
            formIsValid = false;
            errors["mobile"] = "This field is required";
        }
    
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleProfile(e) {
        e.preventDefault();    
        this.setState({ loading : true });
        if (this.handleValidation()) {
            var data = {
                first_name : this.state.first_name,
                last_name : this.state.last_name,
                mobile : this.state.mobile,
            }

            axios.post(`${API_URL}/updateprofile`, data, headers ).then(async res => {
                if(res.data.success === true) {                    
                    await this.getProfile();
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);                    
                }
                await this.setState({ loading : false });
            }).catch(err => {
                this.setState({ loading : false });
                console.log(err);
            })
        } else {
        //   alert("Form has errors.");
            this.setState({ loading : false });
        }
    }
    
    handleReset = async (e) => {
        // Array.from(document.querySelectorAll("input")).forEach(
        //     input => (input.value = "")
        // );

        await this.setState({
            old_password : "",
            new_password : "",
            confirm_password : "",
            fields : {},
            errors : {},
            fields1 : {},
            errors1 : {},
            loading : false
        });        
    }

    handleChange = async (e) => {
        console.log( e.target.value );
        await this.setState({ [e.target.name ] : e.target.value });
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields, loading : false });        
    }

    handleChangePasswordForm = async (e) => {
        await this.setState({ [e.target.name ] : e.target.value });
        let fields1 = this.state.fields1;
        fields1[e.target.name] = e.target.value;
        this.setState({ fields1, loading1 : false });        
    }

    getProfile = () => {
        let url = API_URL+`/getprofile`;
        axios.get(url, headers).then( async res => {
            if(res.data.success === true) {
                // alert(res.data.data.totalDocs)
                let fields = this.state.fields;
                fields['first_name'] = res.data.data.first_name;
                fields['last_name'] = res.data.data.last_name;
                fields['mobile'] = res.data.data.mobile;

                await this.setState({
                    first_name: res.data.data.first_name,
                    last_name: res.data.data.last_name,
                    mobile: res.data.data.mobile
                })
            }
        })
    }

    handleValidationPassword() {
        let fields1 = this.state.fields1;
        let errors1 = {};
        let formIsValid = true;
    
        if (!fields1["old_password"]) {
            formIsValid = false;
            errors1["old_password"] = "This field is required";
        } 

        if (!fields1["new_password"]) {
            formIsValid = false;
            errors1["new_password"] = "This field is required";
        }

        if (!fields1["confirm_password"]) {
            formIsValid = false;
            errors1["confirm_password"] = "This field is required";
        }
    
        this.setState({ errors1: errors1 });
        return formIsValid;
    }

    handlePassword(e) {
        e.preventDefault();    
        this.setState({ loading1 : true });
        if (this.handleValidationPassword()) {
            var data = {
                old_password : this.state.old_password,
                new_password : this.state.new_password,
                confirm_password : this.state.confirm_password,
            }

            axios.post(`${API_URL}/changepassword`, data, headers ).then(async res => {
                if(res.data.success === true) {
                    toast.success(res.data.message);
                    this.handleReset();
                } else {
                    toast.error(res.data.message);                    
                }
                await this.setState({ loading1 : false });
            }).catch(err => {
                this.setState({ loading1 : false });
                console.log(err);
            })
        } else {
        //   alert("Form has errors.");
            this.setState({ loading1 : false });
        }
    }

    inputNumber = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    componentDidMount(){
        this.getProfile();
    }

    render() {
        return (<div>
        <ToastContainer />
        <AppHeader />
        <AppSidebar />  
        <div className="content-wrapper">
            <section className="content-header">
            <h1> My Profile </h1>
                <ol className="breadcrumb">
                    <li><a href="/#"><i className="fa fa-dashboard"></i> Home</a></li>
                    <li className="active">My Profile</li>
                </ol>
            </section>
        
            <section className="content">
                <div className="row">
                    <div className="col-md-6">
                        <div className="box box-info">
                            <div className="box-header with-border">
                                <h3 className="box-title">Update Profile</h3>
                            </div>
                            <form onSubmit={this.handleProfile}>
                                <div className="box-body form-horizontal">
                                    <div className="form-group">
                                        <label for="inputEmail3" className="col-sm-3 control-label">First Name <i className="text-red">*</i></label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" id="first_name" maxlength="25" name="first_name" placeholder="First Name" onChange={this.handleChange} defaultValue={this.state.first_name} />
                                            <div className="text-red">{this.state.errors['first_name']}</div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="inputPassword3" className="col-sm-3 control-label">Last Name <i className="text-red">*</i></label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" id="inputPassword3" maxlength="25" name="last_name" placeholder="last Name" onChange={this.handleChange}  defaultValue={this.state.last_name} />
                                            <div className="text-red">{this.state.errors['last_name']}</div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="inputPassword3" className="col-sm-3 control-label">Mobile <i className="text-red">*</i></label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" id="inputPassword3" name="mobile" maxlength="10" pattern="[0-9]*" placeholder="Mobile"  defaultValue={this.state.mobile} onChange={this.handleChange} onKeyPress={this.inputNumber}/>
                                            <div className="text-red">{this.state.errors['mobile']}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-info pull-right" disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Submit'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="box box-warning">
                            <div className="box-header with-border">
                                <h3 className="box-title">Change Password</h3>
                            </div>
                            <form onSubmit={this.handlePassword}>
                                <div className="box-body form-horizontal">
                                    <div className="form-group">
                                        <label for="inputEmail3" className="col-sm-4 control-label">Old Password</label>
                                        <div className="col-sm-8">
                                            <input type="password" maxlength="20" className="form-control" name="old_password" id="inputEmail3" placeholder="Old Password" onChange={this.handleChangePasswordForm} />
                                            <div className="text-red">{this.state.errors1['old_password']}</div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="inputEmail3" className="col-sm-4 control-label">New Password</label>
                                        <div className="col-sm-8">
                                            <input type="password" maxlength="20" className="form-control" name="new_password" id="inputEmail3" placeholder="New Password" onChange={this.handleChangePasswordForm} />
                                            <div className="text-red">{this.state.errors1['new_password']}</div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="inputPassword3" className="col-sm-4 control-label">Confirm Password</label>
                                        <div className="col-sm-8">
                                            <input type="password" maxlength="20" className="form-control" name="confirm_password" id="inputPassword3" placeholder="Confirm Password" onChange={this.handleChangePasswordForm} />
                                            <div className="text-red">{this.state.errors1['confirm_password']}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-warning pull-right" disabled={this.state.loading1}>{this.state.loading1 ? 'Loading...' : 'Change Password'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    <AppFooter />
    </div>);
    }
}

export default Faq;