import React, { Component } from 'react'
import { APP_KEY, API_URL } from './../config'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { getToken } from './../Utils/Common'
import AppHeader from './Header/Header'
import AppSidebar from './Sidebar/Sidebar'
import AppFooter from './Footer/Footer'
import 'react-toastify/dist/ReactToastify.css'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
// import Moment from 'react-moment'
import CsvDownloader from 'react-csv-downloader'
import Select from 'react-select'

const token = getToken()
const headers = { headers: { 'app-key': APP_KEY, 'x-access-token': token } }

class Dailydeliveryreport extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.config = {}
    this.state = {
      loading: false,
      data: [],
      data2: [],
      selected: '',
      csvdata: [],
      total: 0,
      page: 0,
      size: 10,
      search: '',
      columns: [
        {
          name: 'Name',
          cell: (row) => (
            <>
              {row.first_name} {row.last_name}
            </>
          ),
        },
        {
          name: 'Image',
          cell: (row) => (
            <>
              <img src={row.profile_image} width='50' alt='' />
            </>
          ),
        },
        {
          name: 'Mobile',
          selector: `mobile`,
        },
        {
          name: 'Email',
          selector: `email`,
        },
        {
          name: 'KYC',
          cell: (row) => (
            <>
              {row.is_approved ? (
                <small className='label label-success'>Approved</small>
              ) : (
                <small className='label label-warning'>Pending</small>
              )}
            </>
          ),
        },
      ],
      // columns: [
      //   {
      //     name: 'Restaurant Name',
      //     cell: (row) => <Moment format='DD-MMM-YYYY'>{row._id.date}</Moment>,
      //   },
      //   {
      //     name: 'Restaurant Name',
      //     selector: `name`,
      //   },
      //   {
      //     name: 'Total Order',
      //     selector: `total_order`,
      //   },
      // ],
      deliveryboy: '',
      delivery_boy_id: 'undefined',
      partner_id: 'undefined',
      order_status: 'undefined',
      start_date: '',
      end_date: '',
      user_id: 'all',
      name: 'All',
      partner_name: '-- Select Partners --',
      partners: [],
    }

    this.getDataList = this.getDataList.bind(this)
  }

  handleReset = async (e) => {
    Array.from(document.querySelectorAll('input')).forEach(
      (input) => (input.value = '')
    )
    await this.setState({
      is_update: false,
      partner_id: 'undefined',
      partner_name: '-- Select Partners --',
      delivery_boy_id: 'undefined',
      order_status: 'undefined',
    })
  }

  handleEvent = (event, picker) => {
    // console.log(picker.startDate);
  }

  handleApply = async (event, picker) => {
    let start = moment(picker.startDate).format('YYYY-MM-DD')
    let end = moment(picker.endDate).format('YYYY-MM-DD')
    // alert(start);
    await this.setState({ start_date: start, end_date: end })
    this.getDataList()
  }

  handleCallback(start, end) {
    console.log(start, end)
  }

  handleInputChange = async (options, e) => {
    console.log(options)
    await this.setState({
      selected: options.value,
      search: options.value,
      //   delivery_boy_id: options.value,
      //   user_id: options.value,
      //   name: options.label,
      // deliveryboy: options.map((x) => x.first_name),
    })
    this.getAllDeliveryBoys2()
  }

  getDataList = () => {
    let url =
      API_URL +
      `/dailyOrderReport?start_date=${this.state.start_date}&end_date=${this.state.end_date}&partner_id=${this.state.partner_id}&delivery_boy_id=${this.state.delivery_boy_id}&order_status=${this.state.order_status}`
    axios.get(url, headers).then(async (res) => {
      if (res.data.success === true) {
        let csvdata = this.state.csvdata
        res.data.data.map((e) => {
          return csvdata.push({
            'Order Date': e._id.date,
            'Restaurant Name': e.name,
            'Total Order': e.total_order,
          })
        })
        await this.setState({ data: res.data.data, csvdata: csvdata })
      }
    })
  }

  getAllDeliveryBoys = () => {
    let url =
      API_URL +
      `/delivery-boy/getAllDeliveryBoy?page=${this.state.page}&size=${this.state.size}&search=${this.state.search}`

    axios.get(url, headers).then((res) => {
      if (res.data.success === true) {
        this.setState({
          total: res.data.data.totalDocs,
          data: res.data.data.docs,
          data2: res.data.data.docs,
        })
      }
    })
  }

  getAllDeliveryBoys2 = () => {
    let url =
      API_URL +
      `/delivery-boy/getAllDeliveryBoy?page=${this.state.page}&size=${this.state.size}&search=${this.state.search}`

    axios.get(url, headers).then((res) => {
      if (res.data.success === true) {
        this.setState({
          total: res.data.data.totalDocs,
          data2: res.data.data.docs,
        })
      }
    })
  }

  getDeliveryBoy = () => {
    let url = API_URL + `/deliveryboys`
    axios.get(url, headers).then(async (res) => {
      if (res.data.success === true) {
        var option = []
        option.push({ label: 'All', value: 'all' })
        await res.data.data.map((v) => {
          return option.push({
            value: v._id,
            label: v.first_name + ' ' + v.last_name,
          })
        })
        await this.setState({ delivery_boy: option })
      }
    })
  }

  handlePage = async (page) => {
    await this.setState({ page: page - 1 })
    this.getAllDeliveryBoys()
  }

  getPartner = () => {
    let url = API_URL + `/getPartners`
    axios.get(url, headers).then(async (res) => {
      if (res.data.success === true) {
        var option = []
        option.push({ label: '-- Select Partners --', value: '' })
        await res.data.data.map((v) => {
          return option.push({ value: v._id, label: v.name })
        })
        await this.setState({ partners: option })
      }
    })
  }

  handleChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value })
    this.getDataList()
  }

  handleInputChangePartner = async (options) => {
    await this.setState({
      partner_id: options.value,
      partner_name: options.label,
    })
    this.getDataList()
  }

  componentDidMount() {
    this.getDeliveryBoy()
    this.getPartner()
    this.getAllDeliveryBoys()
    this.getAllDeliveryBoys2()
  }

  render() {
    return (
      <div>
        <AppHeader />
        <AppSidebar />
        <div className='content-wrapper'>
          <section className='content-header'>
            <h1>Daily Order Report</h1>
            <ol className='breadcrumb'>
              <li>
                <a href='/#'>
                  <i className='fa fa-dashboard'></i> Home
                </a>
              </li>
              <li className='active'>Daily Order Report</li>
            </ol>
          </section>
          <section className='content'>
            <div className='row'>
              <div className='col-xs-12'>
                <div className='box'>
                  <div className='box-header'>
                    {/* <div class='form-group col-md-3'>
                      <label>Delivery Partners</label>
                      <Select
                        value={{
                          value: this.state.partner_id,
                          label: this.state.partner_name,
                        }}
                        options={this.state.partners}
                        onChange={this.handleInputChangePartner}
                      />
                    </div> */}
                    <div class='form-group col-md-3'>
                      <label>Delivery Boy</label>
                      <Select
                        // value={this.state.selected}
                        options={this.state.data.map((boy, index) => {
                          return {
                            label: boy.first_name,
                            value: boy.first_name,
                            key: index,
                          }
                        })}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div class='form-group col-md-2'>
                      <label>Order Status</label>
                      <select
                        name='order_status'
                        id='order_status'
                        class='form-control'
                        onChange={this.handleChange}
                      >
                        <option value='undefined'>All</option>
                        <option value='0'>Non-Accepted</option>
                        <option value='1'>Pending</option>
                        <option value='3'>Cancelled</option>
                        <option value='D'>Delivered</option>
                      </select>
                    </div>
                    <div className='form-group col-md-3'>
                      <label>Date range:</label>
                      <DateRangePicker
                        initialSettings={{
                          startDate: moment().subtract(1, 'day').toDate(),
                          locale: { format: 'YYYY-MM-DD' },
                        }}
                        onApply={this.handleApply}
                        onEvent={this.handleEvent}
                        onCallback={this.handleCallback}
                      >
                        <input
                          type='text'
                          className='form-control'
                          id='date_range'
                        />
                      </DateRangePicker>
                    </div>
                    <div
                      className='form-group col-md-1'
                      style={{ marginTop: '2.5rem' }}
                    >
                      <label>&nbsp;&nbsp;&nbsp;</label>
                      <button
                        onClick={this.handleReset}
                        className='btn btn-default'
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className='box-body'>
                    <DataTable
                      columns={this.state.columns}
                      data={this.state.data2}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={this.state.total}
                      paginationPerPage={this.state.size}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      onChangePage={this.handlePage}
                    />
                  </div>
                  {this.state.data.length > 0 ? (
                    <>
                      <div class='box-footer'>
                        <div className='form-group col-md-2 pull-right'>
                          <CsvDownloader
                            className='btn btn-info'
                            filename='daily_delivery_report'
                            extension='.csv'
                            separator=';'
                            datas={this.state.data}
                            text='Download CSV'
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
        <AppFooter />
      </div>
    )
  }
}

export default Dailydeliveryreport
