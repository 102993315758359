import React, { PureComponent } from 'react'
import { getUser } from '../../Utils/Common'
import { Link } from 'react-router-dom'
// import PropTypes from 'prop-types';
// import { Test } from './Sidebar.styles';

class Sidebar extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      hasError: false,
      isShown: false,
    }
  }

  componentWillMount = () => {
    const user = getUser()
    // console.log(user)
    this.setState({ name: user.data.first_name + ' ' + user.data.last_name })
  }

  // componentDidMount = () => {
  //   console.log('Sidebar mounted');
  // }

  // componentWillReceiveProps = (nextProps) => {
  //   console.log('Sidebar will receive props', nextProps);
  // }

  // componentWillUpdate = (nextProps, nextState) => {
  //   console.log('Sidebar will update', nextProps, nextState);
  // }

  // componentDidUpdate = () => {
  //   console.log('Sidebar did update');
  // }

  // componentWillUnmount = () => {
  //   console.log('Sidebar will unmount');
  // }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }
    return (
      <div>
        <aside className='main-sidebar'>
          <section className='sidebar'>
            <div className='user-panel'>
              <div className='pull-left image'>
                <img
                  src='/dist/img/user2-160x160.jpg'
                  className='img-circle'
                  alt=''
                />
              </div>
              <div className='pull-left info'>
                <p>{this.state.name}</p>
                <a href='/#'>
                  <i className='fa fa-circle text-success'></i> Online
                </a>
              </div>
            </div>
            <ul className='sidebar-menu' data-widget='tree'>
              <li className='header'>MAIN NAVIGATION</li>
              <li>
                <Link to='/dashboard'>
                  <i className='fa fa-dashboard'></i> <span>Dashboard</span>
                </Link>
              </li>
              {/* <li>
                <Link to='/deliverypartner'>
                  <i className='fa fa-building-o'></i>{' '}
                  <span>Manage Delivery Partner</span>
                </Link>
              </li> */}
              <li>
                <Link to='/deliveryboy'>
                  <i className='fa fa-users'></i>{' '}
                  <span>Manage Delivery Boy</span>
                </Link>
              </li>
              <li>
                <Link to='/vehicletype'>
                  <i className='fa fa-motorcycle'></i>{' '}
                  <span>Manage Vehicle</span>
                </Link>
              </li>
              {/* webhook */}
              <li>
                <Link to='/webhook'>
                <i class="fa fa-link"></i>{' '}
                  <span>Webhook</span>
                </Link>
              </li>
              
              <li>
                <Link to='/orders'>
                  <i className='fa fa-cart-plus'></i> <span>Orders</span>
                </Link>
              </li>
              <li>
                <Link to='/setting'>
                  <i className='fa fa-gear'></i> <span>Site Setting</span>
                </Link>
              </li>
              <li>
                <Link to='/notifications'>
                  <i className='fa fa-bell'></i> <span>Notifications</span>
                </Link>
              </li>
              {/* <li>
                <Link to='/pages'>
                  <i className='fa fa-file-text-o'></i>{' '}
                  <span>Manage Cms Pages</span>
                </Link>
              </li> */}
              <li>
                <Link to='/faq'>
                  <i className='fa fa-question-circle'></i>{' '}
                  <span>Manage Help / FAQ’s</span>
                </Link>
              </li>
              <li>
                <Link to='/uservoucher'>
                  <i className='fa fa-inr'></i> <span>User Wages</span>
                </Link>
              </li>
              <li>
                <Link to='/profile'>
                  <i className='fa fa-user'></i> <span>My Profile</span>
                </Link>
              </li>
              <li
                className='treeview'
                onMouseEnter={() => this.setState({ isShown: true })}
                onMouseLeave={() => this.setState({ isShown: false })}
              >
                <a href='/#'>
                  <i className='fa fa-files-o'></i>
                  <span>Reports</span>
                  &nbsp; &nbsp;
                  <i class='fa fa-caret-down'></i>
                </a>
                {this.state.isShown && (
                  <ul>
                    <li style={{ padding: '5px' }}>
                      <Link to='/dailydeliveryreport'>
                        <i className='fa fa-circle-o'></i>
                        <span>Daily Delivery Report</span>
                      </Link>
                    </li>
                    {/* <li style={{ padding: '5px' }}>
                      <Link to='/deliveryboyreport'>
                        <i className='fa fa-circle-o'></i>
                        <span>Delivery Boy Wise Report</span>
                      </Link>
                    </li>
                    <li style={{ padding: '5px' }}>
                      <Link to='/restaurantreport'>
                        <i className='fa fa-circle-o'></i>
                        <span>Restaurant Wise Report</span>
                      </Link>
                    </li> */}
                    <li style={{ padding: '5px' }}>
                      <Link to='/logisticreport'>
                        <i className='fa fa-circle-o'></i>
                        <span>Logistic Report</span>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </section>
        </aside>
      </div>
    )
  }
}

// Sidebar.propTypes = {
//   // bla: PropTypes.string,
// };

// Sidebar.defaultProps = {
//   // bla: 'test',
// };

export default Sidebar
